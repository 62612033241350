// import { useIntl } from 'umi';

import type { CompanyModel } from '@/services/datatypes/Company';
import type { UserModel } from '@/services/datatypes/User';
// import { Rate } from 'antd';
import { history } from 'umi';
// import styles from './index.less';

type Props = {
  userModel?: UserModel;
  companyModel?: CompanyModel;
} & React.HTMLAttributes<HTMLDivElement>;

const RatingBar: React.FC<Props> = (props) => {
  const maxRating = 5;
  const intervalWidth = 5;
  const starSize = 15;
  const fillColor = '#fadb14';
  const unfillColor = '#f0f0f0';
  let valueRaw: number;
  let count;
  let action;
  let targetIdStr;
  if (props.userModel != undefined) {
    valueRaw = props.userModel.rating.value ?? 0;
    count = props.userModel.ratingCnt.value;
    targetIdStr = 'u-' + props.userModel.userId.value;
    action = () => {
      history.push({
        pathname: '/ratinglist',
        state: {
          targetUserId: props.userModel?.userId.serverValue(),
        },
      });
    };
  } else if (props.companyModel != undefined) {
    targetIdStr = 'c-' + props.companyModel.companyId.value;
    valueRaw = props.companyModel.rating.value ?? 0;
    count = props.companyModel.ratingCnt.value;
    action = () => {
      history.push({
        pathname: '/ratinglist',
        state: {
          targetCompanyId: props.companyModel?.companyId.serverValue(),
        },
      });
    };
  } else {
    throw new Error('target not setting');
  }

  const starList = [];
  for (let i = 0; i < maxRating; i++) {
    if (i != 0) {
      starList.push(
        <div
          style={{
            display: 'inline-block',
            width: intervalWidth,
          }}
        />,
      );
    }
    const ratingForThisIconRaw = valueRaw - i;
    const ratingForThisIcon = Math.min(Math.max(ratingForThisIconRaw, 0), 1);
    const ratingForThisIconPercent = Math.ceil(ratingForThisIcon * 100);
    let toFillColor;
    let toFillColorSuplement;
    if (ratingForThisIconPercent == 100) {
      toFillColor = fillColor;
      toFillColorSuplement = fillColor;
    } else if (ratingForThisIconPercent == 0) {
      toFillColor = unfillColor;
      toFillColorSuplement = unfillColor;
    } else {
      toFillColor = fillColor;
      toFillColorSuplement = unfillColor;
    }
    const ratingForThisIconOffset = ratingForThisIconPercent.toString() + '%';
    const ratingForThisIconOffsetSuplement = (100 - ratingForThisIconPercent).toString() + '%';
    starList.push(
      <svg
        key={'star-' + i}
        xmlns="http://www.w3.org/2000/svg"
        width="256px"
        height="256px"
        viewBox="0 0 32 32"
        fontSize={starSize}
      >
        <defs>
          <linearGradient id={'grad-' + i + targetIdStr}>
            <stop offset={ratingForThisIconOffset} stop-color={toFillColor} />
            <stop offset={ratingForThisIconOffsetSuplement} stop-color={toFillColorSuplement} />
          </linearGradient>
        </defs>
        <path
          fill={'url(#grad-' + i + targetIdStr + ')'}
          d="M20.388,10.918L32,12.118l-8.735,7.749L25.914,31.4l-9.893-6.088L6.127,31.4l2.695-11.533L0,12.118
l11.547-1.2L16.026,0.6L20.388,10.918z"
        />
      </svg>,
    );
  }

  return (
    <span onClick={action}>
      <div
        style={{
          display: 'inline-block',
          width: intervalWidth,
        }}
      />
      <span
        style={{
          fontWeight: 'bold',
          color: '#0372c8',
          fontSize: Math.ceil((starSize / 20) * 4 + starSize),
          verticalAlign: 'middle',
        }}
      >
        {starList}
        <div
          style={{
            display: 'inline-block',
            width: intervalWidth,
          }}
        />
        {count}
      </span>
    </span>
  );
};

export default RatingBar;
