import type { FunctionComponent } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';

import { List } from 'antd';
import type { EventListInfo } from '@/services/EventService';
import { EventService } from '@/services/EventService';
import type { EventKind } from '@/services/datatypes/Kind';
import AsyncOnclickButton from '../AsyncOnclickButton';
import { LoadingOutlined } from '@ant-design/icons';
import QuudyHomeEventTitle from '../QuudyHomeEventTitle';

type QuudyHomeEventTitleListState = EventListInfo & {
  nowCount: number | undefined;
};

const STEP_COUNT = 10;

// const wait = async (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

type Props = {
  targetEventKinds: EventKind[];
  initEventListInfo?: EventListInfo;
} & React.HTMLAttributes<HTMLDivElement>;

export const EventTitleList: FunctionComponent<Props> = (props) => {
  const [state, setState] = useState<QuudyHomeEventTitleListState>({
    eventList: props.initEventListInfo?.eventList ?? [],
    totalCount: props.initEventListInfo?.totalCount ?? Number.MAX_VALUE,
    nowCount: props.initEventListInfo?.eventList.length,
  });
  const loadData = async (moreCount = 0) => {
    const service = new EventService();
    const tryNextCount = (state.nowCount ?? 0) + moreCount;
    const result = await service.searchEventListByClientAreaAndKind(
      {},
      props.targetEventKinds,
      null,
      0,
      tryNextCount,
    );
    // await wait(1000);
    return result;
  };

  const onLoadMore = async () => {
    const nextStep =
      state.eventList.length + STEP_COUNT > state.totalCount
        ? state.totalCount - state.eventList.length
        : STEP_COUNT;
    // setState({
    //   eventList: state.eventList.concat([...new Array(nextStep)].map(() => ({ eventId: null }))),
    //   totalCount: state.totalCount,
    //   nowCount: state.eventList.length + nextStep,
    // });
    const result = await loadData(nextStep);
    setState({
      eventList: result.eventList,
      totalCount: result.totalCount,
      nowCount: result.eventList.length,
    });
  };

  const loadMore =
    state.nowCount === undefined || state.nowCount >= state.totalCount ? null : (
      <div
        style={{
          textAlign: 'center',
          marginTop: 12,
          height: 32,
          lineHeight: '32px',
        }}
      >
        {/* <Button onClick={onLoadMore}>もっと見る</Button> */}
        <AsyncOnclickButton size="small" shape="default" asyncProcesses={[onLoadMore]}>
          もっと見る
        </AsyncOnclickButton>
      </div>
    );
  useEffect(() => {
    if (state.nowCount === undefined) {
      onLoadMore();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.nowCount]);
  const list = (
    <List
      loading={
        state.nowCount === undefined
          ? { indicator: <LoadingOutlined style={{ fontSize: 24 }} spin /> }
          : undefined
      }
      itemLayout="horizontal"
      loadMore={loadMore}
      dataSource={state.eventList}
      renderItem={(item, index) => (
        <QuudyHomeEventTitle
          key={item.eventId === null ? index.toString() : item.eventId}
          eventInfo={item}
        />
      )}
    />
  );
  return <div>{list}</div>;
};

export default EventTitleList;
