// export interface BaseModel {

import type { OneOfModels } from '../utils/ModelMetaDataUtil';
import type { ValueType } from './types';
import { BaseType, isAbleToServerValue } from './types';

// MODEL_NAME属性是string，其他属性则是BaseType
// 这个类型没有强制要求MODEL_NAME
// type ModelName = {
//   MODEL_NAME:string,
// }

// 基础模型Model是一个属性全是由BaseType组成的类型
// export type BaseModel = RecordA<string>
// type RecordA<K extends keyof any> = {
//   [P in K]: P extends 'MODEL_NAME'? string:BaseType<ValueType>;
// };

export type BaseModel = Record<string, BaseType<ValueType>>;

export type TypeInfoOf<T extends OneOfModels> = {
  [P in keyof T]: T[P]['typeInfo'];
};

export type ModelServerData<MT extends OneOfModels> = Partial<Record<keyof MT, string | null>>;

export type ModelColumn<MT extends OneOfModels> = keyof MT;

export type ModelColumns<MT extends OneOfModels> = (keyof MT)[];

export function modelToJSON(model: OneOfModels): ModelServerData<OneOfModels> {
  const result: Record<string, any> = {};
  for (const propertyName in model) {
    if (model.hasOwnProperty(propertyName)) {
      const property = model[propertyName];
      if (isAbleToServerValue(property)) {
        result[propertyName] = property.serverValue();
      }
    }
  }
  return result;
}

export function isOneOfModels(obj: any): obj is OneOfModels {
  if (typeof obj !== 'object' || Object === null) {
    return false;
  }
  for (const propertyName in obj) {
    if (obj.hasOwnProperty(propertyName)) {
      const property = obj[propertyName];
      if (!(property instanceof BaseType)) {
        return false;
      }
    }
  }
  return true;
}

// export function modelColumns<MT extends Models>(modelOrConstructor:MT|((...args: any)=>MT)):ModelColumns<MT>{
//     let model:MT;
//     if (typeof modelOrConstructor === 'function') {
//         model = modelOrConstructor();
//     } else {
//         model = modelOrConstructor;
//     }
//     let result = [] as ModelColumns<MT>;
//     for (const propertyName in model){
//       if (model.hasOwnProperty(propertyName)) {
//         let property = model[propertyName];
//         if (property instanceof BaseType) {
//             result.push(propertyName);
//         }
//       }
//     }
//     return result;
// }
