import type { FunctionComponent } from 'react';
import { useContext } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';

import { Button, Popover } from 'antd';
import { EnvironmentOutlined, LoadingOutlined } from '@ant-design/icons';

import { DistrictModel } from '../services/datatypes/District';
import { KenModel } from '../services/datatypes/Ken';
import { CityModel } from '../services/datatypes/City';
import { TownModel } from '../services/datatypes/Town';
import { AddressService } from '../services/AddressService';
import type { MenuData } from './Common/menu/MenuCommon';
import GridMenu from './Common/menu/GridMenu';
import { useCookies } from 'react-cookie';
import type { ModelServerData } from '@/services/datatypes/BaseModel';
import quudyConfig from '@/quudyConfig';
import { QuudyUserInfoContext } from './QuudyPageLayout';
import { ObjectUtils2 } from '@/services/utils/ObjectUtils2';

// 水平居中样式
const containerForVerticalCenter = { position: 'relative' as 'relative' };
const contentVerticalCenter = {
  margin: 0,
  position: 'absolute' as 'absolute',
  top: '50%',
  msTransform: 'translateY(-50%)',
  transform: 'translateY(-50%)',
};

type Props = {
  // initAddress: ModelServerData<DistrictModel | KenModel | CityModel | TownModel>;
  showButtonName: boolean;
};

export type SelectedAddressInfo = {
  district: DistrictModel;
  ken: KenModel;
  city?: CityModel;
  town?: TownModel;
};

type AddressSelectState = {
  selectedAddress?: SelectedAddressInfo;
  rootMenuData: MenuData;
  loading: boolean;
  menuVisible: boolean;
};

export const AddressSelect: FunctionComponent<Props & React.HTMLAttributes<HTMLDivElement>> = (
  props,
) => {
  // const { initialState } = useModel('@@initialState');
  const quudyUserContext = useContext(QuudyUserInfoContext)!;
  const setSelectedAddressInfo = quudyUserContext?.setSelectedAddress;

  const prefix = quudyConfig.cookieKeyPrefix;
  const [cookies, _setCookie, _removeCookie] = useCookies([
    prefix + 'district_id',
    prefix + 'ken_id',
    prefix + 'city_id',
    prefix + 'town_id',
  ]);
  const cookie = (name: string) => {
    return cookies[prefix + name];
  };
  const setCookie = (
    name: string,
    value: any,
    options?:
      | {
          path: string;
          domain: string;
          secure: boolean;
          sameSite: 'none';
          maxAge: number;
        }
      | undefined,
  ) => {
    _setCookie(prefix + name, value, options);
  };
  const removeCookie = (
    name: string,
    options?:
      | {
          path: string;
          domain: string;
          secure: boolean;
          sameSite: 'none';
          maxAge: number;
        }
      | undefined,
  ) => {
    _removeCookie(prefix + name, options);
  };

  const rootMenuData: MenuData = {
    data: undefined,
    menuKey: '0',
    menuTitle: '地域',
    order: 0,
  };

  const initialAddressSelectState = {
    rootMenuData: rootMenuData,
    loading: false,
    menuVisible: false,
  };

  const [state, setState] = useState<AddressSelectState>(initialAddressSelectState);
  function selectAddress(menuData: MenuData<TownModel> | MenuData<CityModel> | MenuData<KenModel>) {
    if (menuData.data instanceof KenModel) {
      const kenMenuData = menuData as MenuData<KenModel>;
      setState((preState) => {
        // const cityMenuData = (townMenuData.parentMenuData as MenuData<CityModel>);
        // const kenMenuData = (cityMenuData.parentMenuData as MenuData<KenModel>);
        const districtMenuData = kenMenuData.parentMenuData as MenuData<DistrictModel>;
        const addressData = {
          district: districtMenuData.data!,
          ken: kenMenuData.data!,
          city: undefined,
          town: undefined,
        };
        return {
          selectedAddress: addressData,
          rootMenuData: preState.rootMenuData,
          loading: preState.loading,
          menuVisible: false,
        };
      });
    } else if (menuData.data instanceof CityModel) {
      const cityMenuData = menuData as MenuData<CityModel>;
      setState((preState) => {
        // const cityMenuData = (townMenuData.parentMenuData as MenuData<CityModel>);
        const kenMenuData = cityMenuData.parentMenuData as MenuData<KenModel>;
        const districtMenuData = kenMenuData.parentMenuData as MenuData<DistrictModel>;
        const addressData = {
          district: districtMenuData.data!,
          ken: kenMenuData.data!,
          city: cityMenuData.data!,
          town: undefined,
        };
        return {
          selectedAddress: addressData,
          rootMenuData: preState.rootMenuData,
          loading: preState.loading,
          menuVisible: false,
        };
      });
    } else {
      const townMenuData = menuData as MenuData<TownModel>;
      setState((preState) => {
        const cityMenuData = townMenuData.parentMenuData as MenuData<CityModel>;
        const kenMenuData = cityMenuData.parentMenuData as MenuData<KenModel>;
        const districtMenuData = kenMenuData.parentMenuData as MenuData<DistrictModel>;
        const addressData = {
          district: districtMenuData.data!,
          ken: kenMenuData.data!,
          city: cityMenuData.data!,
          town: townMenuData.data!,
        };
        return {
          selectedAddress: addressData,
          rootMenuData: preState.rootMenuData,
          loading: preState.loading,
          menuVisible: false,
        };
      });
    }
  }

  async function initialize() {
    let initAddressRawData: {
      district: ModelServerData<DistrictModel>;
      ken: ModelServerData<KenModel>;
      city: ModelServerData<CityModel>;
      town: ModelServerData<TownModel>;
    };
    if (cookie('ken_id') !== undefined) {
      // 选择过后的case
      initAddressRawData = await new AddressService().getAddressInfo(
        cookie('ken_id'),
        cookie('city_id'),
        cookie('town_id'),
      );
    } else {
      // 没有选择过，则是以东京都为初始值
      initAddressRawData = await new AddressService().getAddressInfo('13');
    }

    const initAddressata = {
      district: new DistrictModel(initAddressRawData.district),
      ken: new KenModel(initAddressRawData.ken),
      city: initAddressRawData.city === null ? undefined : new CityModel(initAddressRawData.city),
      town: initAddressRawData.town === null ? undefined : new TownModel(initAddressRawData.town),
    };
    setSelectedAddressInfo(initAddressata);
    setState((preState) => ({
      selectedAddress: initAddressata,
      rootMenuData: preState.rootMenuData,
      loading: preState.loading,
      menuVisible: preState.menuVisible,
    }));
  }
  useEffect(() => {
    // 初始化
    if (
      // 被更新过
      state.selectedAddress?.ken?.kenId?.serverValue() !== cookie('ken_id') ||
      state.selectedAddress?.city?.cityId?.serverValue() !== cookie('ken_id') ||
      state.selectedAddress?.town?.townId?.serverValue() !== cookie('town_id') ||
      // 初期化
      cookie('ken_id') == undefined
    ) {
      initialize();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cookie('ken_id'), cookie('city_id'), cookie('town_id')]);
  useEffect(() => {
    // 选择之后的副作用，设定cookie
    if (
      state.selectedAddress !== undefined &&
      (state.selectedAddress.district?.districtId.value != cookie('district_id') ||
        state.selectedAddress.ken?.kenId.value != cookie('ken_id') ||
        state.selectedAddress.city?.cityId.value != cookie('city_id') ||
        state.selectedAddress.town?.townId.value != cookie('town_id'))
    ) {
      const option = quudyConfig.cookieOptionsDefault;
      setCookie('district_id', state.selectedAddress.district!.districtId.value, option);
      setCookie('ken_id', state.selectedAddress.ken!.kenId.value, option);
      const cityId = state.selectedAddress.city?.cityId.value;
      if (!ObjectUtils2.isNullOrEmpty(cityId)) {
        setCookie('city_id', cityId, option);
      } else {
        removeCookie('city_id', option);
      }
      const townId = state.selectedAddress.town?.townId.value;
      if (!ObjectUtils2.isNullOrEmpty(townId)) {
        setCookie('town_id', state.selectedAddress.town?.townId.value, option);
      } else {
        removeCookie('town_id', option);
      }
      setSelectedAddressInfo(state.selectedAddress);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.selectedAddress]);

  async function townSubMenuFetcher(
    cityMenuData: MenuData<CityModel>,
  ): Promise<MenuData<TownModel>[]> {
    // 查询町域菜单
    const towns = await new AddressService().getTownsOfCitySV(cityMenuData.menuKey);
    const townSubMenuData: MenuData<TownModel>[] = towns.map((town) => {
      const townMenuData = {
        menuKey: town.townId.serverValue()!,
        data: town,
        menuTitle: town.townName.value === null ? '' : town.townName.value,
        order: town.townId.value!,
        parentMenuData: cityMenuData,
        subMenuFetcher: undefined,
        subMenuItems: undefined,
        menuItemSelectedCallBack: () => {
          selectAddress(townMenuData);
        },
      };
      return townMenuData;
    });
    return townSubMenuData;
  }

  async function citySubMenuFetcher(kenMenuData: MenuData): Promise<MenuData[]> {
    // 查询市区町村菜单
    const cities = await new AddressService().getCitiesOfKenSV(kenMenuData.menuKey);
    const citySubMenuData: MenuData<CityModel>[] = cities.map((city) => {
      const cityMenuData = {
        menuKey: city.cityId.serverValue()!,
        data: city,
        menuTitle: city.cityName.value === null ? '' : city.cityName.value,
        order: city.cityId.value!,
        parentMenuData: kenMenuData,
        subMenuFetcher: async () => {
          return await townSubMenuFetcher(cityMenuData);
        },
        subMenuItems: undefined,
        menuItemSelectedCallBack: undefined,
        extraActions: [
          {
            actionName: '選択',
            action: () => {
              selectAddress(cityMenuData);
            },
          },
        ],
      };
      return cityMenuData;
    });
    return citySubMenuData;
  }

  async function kenSubMenuFetcher(districtMenuData: MenuData): Promise<MenuData[]> {
    // 查询县菜单
    const kens = await new AddressService().getKensOfDistrictSV(districtMenuData.menuKey);
    const kenSubMenuData: MenuData[] = kens.map((ken) => {
      const kenMenuData = {
        menuKey: ken.kenId.serverValue()!,
        data: ken,
        menuTitle: ken.kenName.value === null ? '' : ken.kenName.value,
        order: ken.orderNum.value!,
        parentMenuData: districtMenuData,
        subMenuFetcher: async () => {
          return await citySubMenuFetcher(kenMenuData);
        },
        subMenuItems: undefined,
        menuItemSelectedCallBack: undefined,
        extraActions: [
          {
            actionName: '選択',
            action: () => {
              selectAddress(kenMenuData);
            },
          },
        ],
      };
      return kenMenuData;
    });
    return kenSubMenuData;
  }

  async function districtsSubMenuFetcher(): Promise<MenuData[]> {
    // 查询地域菜单
    const districts = await new AddressService().getDistricts();
    const districtSubMenuData: MenuData<DistrictModel>[] = districts.map((district) => {
      const districtMenuData = {
        menuKey: district.districtId.serverValue()!,
        data: district,
        menuTitle: district.districtName.value === null ? '' : district.districtName.value,
        order: district.districtId.value!,
        parentMenuData: rootMenuData,
        subMenuFetcher: async () => {
          return await kenSubMenuFetcher(districtMenuData);
        },
        subMenuItems: undefined,
        menuItemSelectedCallBack: undefined,
      };
      return districtMenuData;
    });
    return districtSubMenuData;
  }

  rootMenuData.subMenuFetcher = districtsSubMenuFetcher;

  let buttonName: string = '';
  if (state.selectedAddress !== undefined) {
    if (state.selectedAddress.town !== undefined) {
      if (state.selectedAddress.town.townName.value !== null) {
        buttonName = state.selectedAddress.town.townName.value;
      }
    } else if (state.selectedAddress.city !== undefined) {
      if (state.selectedAddress.city.cityName.value !== null) {
        buttonName = state.selectedAddress.city.cityName.value;
      }
    } else if (state.selectedAddress.ken !== undefined) {
      if (state.selectedAddress.ken.kenName.value !== null) {
        buttonName = state.selectedAddress.ken.kenName.value;
      }
    } else if (state.selectedAddress.district !== undefined) {
      if (state.selectedAddress.district.districtName.value !== null) {
        buttonName = state.selectedAddress.district.districtName.value;
      }
    }
  }

  async function initRootMenu() {
    if (state.rootMenuData.subMenuItems === undefined) {
      const newMenuData = Object.assign({}, state.rootMenuData);
      newMenuData.subMenuFetcher = districtsSubMenuFetcher;
      setState((preState) => {
        return {
          selectedAddress: preState.selectedAddress,
          rootMenuData: preState.rootMenuData,
          loading: true,
          menuVisible: preState.menuVisible,
        };
      });
      newMenuData.subMenuItems = await districtsSubMenuFetcher();
      setState((preState) => {
        return {
          selectedAddress: preState.selectedAddress,
          rootMenuData: newMenuData,
          loading: false,
          menuVisible: preState.menuVisible,
        };
      });
    }
  }

  async function handleVisibleChange(visible: boolean) {
    if (visible) {
      await initRootMenu();
    }
    setState((preState) => {
      return {
        selectedAddress: preState.selectedAddress,
        rootMenuData: preState.rootMenuData,
        loading: preState.loading,
        menuVisible: visible,
        // initialized: preState.initialized,
      };
    });
  }

  return (
    <div
      style={Object.assign(
        {
          height: '100%',
          width: props.showButtonName ? '120px' : '50px',
          paddingLeft: '10px',
          paddingTop: '5px',
          paddingBottom: '5px',
        },
        containerForVerticalCenter,
      )}
    >
      <Popover
        content={<GridMenu initMenuData={state.rootMenuData} />}
        trigger="click"
        visible={state.selectedAddress === undefined ? false : state.menuVisible}
        onVisibleChange={
          state.loading || state.selectedAddress === undefined ? undefined : handleVisibleChange
        }
        overlayStyle={{
          width: 320,
          height: 300,
        }}
        placement="bottomLeft"
      >
        {props.showButtonName ? (
          <Button
            style={contentVerticalCenter}
            shape="round"
            icon={state.loading ? <LoadingOutlined /> : <EnvironmentOutlined />}
          >
            {buttonName}
          </Button>
        ) : (
          <Button
            style={contentVerticalCenter}
            shape="circle"
            icon={state.loading ? <LoadingOutlined /> : <EnvironmentOutlined />}
          />
        )}
      </Popover>
    </div>
  );
};

export default AddressSelect;
