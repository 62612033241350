import { isOneOfModels, modelToJSON } from '../datatypes/BaseModel';

// 需要注意
// 数组不是plain object
export class ObjectUtils {
  static clone(stateObject: Record<string, any>): Record<string, any> {
    if (typeof stateObject !== 'object' || stateObject === null || stateObject === undefined) {
      // 一般对象或者null值都是直接返回。
      return stateObject;
    } else if (Array.isArray(stateObject)) {
      return stateObject.map((elem) => ObjectUtils.clone(elem));
    } else if (ObjectUtils.isPlainObject(stateObject)) {
      const result = Object.assign({}, stateObject);
      for (const propertyName in stateObject) {
        if (stateObject.hasOwnProperty(propertyName)) {
          const property = stateObject[propertyName] as Record<string, any>;
          result[propertyName] = ObjectUtils.clone(property);
        }
      }
      return result;
    } else if (isOneOfModels(stateObject)) {
      const json = modelToJSON(stateObject);
      // @ts-ignore typescript缺少识别对象构造器的功能
      const res = new stateObject.constructor(json);
      return res;
    } else {
      throw new Error('not support non-plain-object witch is not Model');
    }
  }

  static isPlainObject(obj: any): boolean {
    if (typeof obj !== 'object' || obj === null) {
      return false;
    }
    let proto = obj; // proto 默认值
    while (Object.getPrototypeOf(proto) !== null) {
      // 判断proto的原型对象是否存在
      proto = Object.getPrototypeOf(proto); // 存在的话就把proto的原型对象赋值给proto,继续判断
    }
    return Object.getPrototypeOf(obj) === proto; // 实际上就是判断obj的原型链有几层，只有一层就返回true
  }

  static isSameArray(...targets: any[][]): boolean {
    const firstTarget = targets[0];
    const isArrayReducer = (previousValue: boolean, currentValue: any) =>
      previousValue && Array.isArray(currentValue);
    const isAllArray = targets.reduce(isArrayReducer, true);
    if (!isAllArray) {
      return false;
    }
    const arrayLengthReducer = (previousValue: boolean, currentValue: any[]) =>
      previousValue && currentValue.length === firstTarget.length;
    const allLenthEqual = targets.reduce(arrayLengthReducer, true);
    if (!allLenthEqual) {
      return false;
    } else {
      const length = firstTarget.length;
      for (let i = 0; i < length; i++) {
        const elementsAtIndex = targets.map((target) => target[i]);
        // 递归调用比较每一个数组的相同位置的元素。
        if (!ObjectUtils.isSame(elementsAtIndex)) {
          return false;
        }
      }
      // 没有不同的元素那就是相同
      return true;
    }
  }

  static isSame(...targets: any[]): boolean {
    if (targets.length === 0) {
      return true;
    }
    const firstTarget = targets[0];
    if (typeof firstTarget !== 'object') {
      // 比较的不是对象？全部元素和第一个元素比较，全部相同的时候为相同
      const reducer = (previousValue: boolean, currentValue: any) =>
        previousValue && Object.is(firstTarget, currentValue);
      return targets.reduce(reducer, true);
    } else {
      // 比较的是对象
      if (Array.isArray(firstTarget)) {
        // 比较的对象是数组
        return ObjectUtils.isSameArray(targets);
      } else {
        // 比较的是非数组，并且是plain对象
        targets.forEach((target) => {
          if (ObjectUtils.isPlainObject(target)) {
            throw new Error('not support non-plain-object');
          }
        });
        const targetsKeys = targets.map((target) => Object.keys(target));
        if (!ObjectUtils.isSameArray(targetsKeys)) {
          return false;
        }
        const targetsValues = targets.map((target) => Object.values(target));
        if (!ObjectUtils.isSameArray(targetsValues)) {
          return false;
        }
        return true;
      }
    }
  }
}
