import type { FormType } from '@/components/QuudyForm/DefaultQuudyForm';
import camelcaseKeys from 'camelcase-keys';
import { BaseService, BussinessLogicException } from './BaseService';
import type { ModelColumns, ModelServerData } from './datatypes/BaseModel';
import type { CityModel } from './datatypes/City';
import type { CompanyModel } from './datatypes/Company';
import type { EventModel } from './datatypes/Event';
import type { EventFilesModel } from './datatypes/EventFiles';
import type { EventMansionModel } from './datatypes/EventMansion';
import type { EventKind, KindModel } from './datatypes/Kind';
import type { TownModel } from './datatypes/Town';
import type { UserModel } from './datatypes/User';
import { NeighborhoodModel } from './datatypes/Neighborhood';

export class EventService extends BaseService {
  async searchEventListByClientAreaAndKind(
    params: ModelServerData<EventModel>,
    targetKinds: EventKind[],
    targetCols: ModelColumns<EventModel> | null,
    offset: number,
    limit: number,
  ): Promise<EventListInfo> {
    const result = await super.invokeService<any>(
      'event',
      'search_event_list_by_client_area_and_kind',
      { methodParams: [params, targetKinds, targetCols, offset, limit] },
    );
    return {
      eventList: camelcaseKeys(result[0], { deep: true }),
      totalCount: result[1],
    } as EventListInfo;
  }

  async getEventPerKindNumber(
    params: ModelServerData<EventModel>,
    targetKinds: EventKind[],
  ): Promise<EventPerKindNumberInfo> {
    const result = await super.invokeService<any>('event', 'get_event_per_kind_number', {
      methodParams: [params, targetKinds],
    });
    return camelcaseKeys(result, { deep: true }) as EventPerKindNumberInfo;
  }

  async issueEventFormInfo(): Promise<FormType> {
    const result = await super.invokeService<any>('event', 'issue_event_form_info', {});
    return camelcaseKeys(result, { deep: true }) as FormType;
  }

  async getEventModifyInfo(eventId: string): Promise<EventModifyInfo> {
    const result = await super.invokeService<any>('event', 'get_event_modify_info', {
      methodParams: [eventId],
    });
    result.event_mansion = result.event_mansion.map(
      (eventMansionResult: { event_mansion: any }) => eventMansionResult.event_mansion,
    );
    result.event_files = result.event_files.map(
      (eventFilesResult: { event_files: any }) => eventFilesResult.event_files,
    );
    return camelcaseKeys(result, { deep: true }) as EventModifyInfo;
  }

  async issueNewEvent(form: Record<string, any>): Promise<void> {
    await super.invokeService<any>('event', 'issue_new_event', { methodParams: [form] });
  }

  async modifyOldEvent(form: Record<string, any>): Promise<undefined | BussinessLogicException> {
    return await super.invokeService<undefined>('event', 'modify_old_event', {
      methodParams: [form],
    });
  }

  async getClientEventList(offset: number, limit: number): Promise<EventListInfo> {
    const result = await super.invokeService<any>('event', 'get_client_event_list', {
      methodParams: [offset, limit],
    });
    return {
      eventList: camelcaseKeys(result[0], { deep: true }),
      totalCount: Number.parseInt(result[1]),
    } as EventListInfo;
  }

  async getClientEventDetailInfo(eventId: string): Promise<EventDetailInfo> {
    const result = await super.invokeService<any>('event', 'get_client_event_detail_info', {
      methodParams: [eventId],
    });
    result.event_files = result.event_files.map(
      (eventFilesResult: { event_files: any }) => eventFilesResult.event_files,
    );
    return camelcaseKeys(result, { deep: true }) as EventDetailInfo;
  }
}

export type EventListInfo = {
  eventList: ModelServerData<EventModel>[];
  totalCount: number;
};

export type EventDetailInfo = {
  event: ModelServerData<EventModel>;
  user: ModelServerData<UserModel>;
  company: ModelServerData<CompanyModel>;
  neighborhood: ModelServerData<NeighborhoodModel>;
  city: ModelServerData<CityModel>;
  town: ModelServerData<TownModel>;
  kind: ModelServerData<KindModel>;
  eventFiles: ModelServerData<EventFilesModel>[];
};

export type EventModifyInfo = {
  event: ModelServerData<EventModel>;
  // user: ModelServerData<UserModel>;
  // company: ModelServerData<CompanyModel>;
  // city: ModelServerData<CityModel>;
  // town: ModelServerData<TownModel>;
  // kind: ModelServerData<KindModel>;
  eventMansion: ModelServerData<EventMansionModel>[];
  eventFiles: ModelServerData<EventFilesModel>[];
};

export type EventPerKindNumberInfo = {
  kindId: string;
  kindEventNum: string;
  kindName: string;
  kindDescription: string;
  kindColor: string;
}[];
