import type { FunctionComponent } from 'react';
import type { MenuData } from './MenuCommon';
import { useState } from 'react';
// import 'antd/dist/antd.css';
import { Button, PageHeader, Row, Col } from 'antd';
import { LeftOutlined } from '@ant-design/icons';
import LoadingIndicator from '@/components/QuudyLoadingPage/LoadingIndicator';

type Props = {
  initMenuData: MenuData;
};

type MenuState = {
  menuData: MenuData;
  loading: boolean;
};

export const GridMenu: FunctionComponent<Props> = (props) => {
  const [state, setState] = useState<MenuState>({
    menuData: props.initMenuData,
    loading: false,
  });
  // 遍历状态中的菜单，如果是clickTarget则向查询子菜单，如果不是，则单纯复制当前地址数据，最终获得新的地址数据，并更新状态
  async function clickSubMenuOrMenuItem(clickTarget: MenuData): Promise<void> {
    async function getNewMenudata(menuData: MenuData): Promise<MenuData | null> {
      if (clickTarget === menuData) {
        // 状态树的变化点
        let newMenuData;
        if (clickTarget.subMenuItems === undefined && clickTarget.subMenuFetcher !== undefined) {
          newMenuData = Object.assign({}, menuData);
          // 子菜单没有取得则取得子菜单
          const newSubMenuData = await clickTarget.subMenuFetcher();
          newMenuData.subMenuItems = newSubMenuData;
        } else if (
          clickTarget.subMenuItems === undefined &&
          clickTarget.subMenuFetcher === undefined
        ) {
          // 没有任何子菜单，保持现有选中菜单不变。
          newMenuData = state.menuData;
        } else {
          newMenuData = menuData;
        }
        if (clickTarget.menuItemSelectedCallBack !== undefined) {
          clickTarget.menuItemSelectedCallBack();
        }
        return newMenuData;
      } else {
        // 不是状态变化点,遍历查找到状态变化点
        if (menuData.parentMenuData !== undefined) {
          // 父菜单状态子树
          const result = await getNewMenudata(menuData.parentMenuData);
          if (result != null) {
            return result;
          }
        }
        if (menuData.subMenuItems !== undefined) {
          // 子菜单状态子树
          for (const subMenuItem of menuData.subMenuItems) {
            const result = await getNewMenudata(subMenuItem);
            if (result != null) {
              return result;
            }
          }
        }
        // 没找到则返回null
        return null;
      }
    }
    setState({
      menuData: state.menuData,
      loading: true,
    });
    const newState = await getNewMenudata(state.menuData);
    if (newState === null) {
      throw new Error('Click target menu data not exists');
    }
    setState({
      menuData: newState,
      loading: false,
    });
  }

  const subMenus = state.menuData.subMenuItems === undefined ? [] : state.menuData.subMenuItems;

  return (
    <PageHeader
      ghost={false}
      backIcon={<LeftOutlined />}
      onBack={
        state.menuData.parentMenuData === undefined
          ? undefined
          : () => {
              clickSubMenuOrMenuItem(state.menuData.parentMenuData!);
            }
      }
      title={state.menuData.menuTitle}
      style={{
        padding: 0,
      }}
      extra={state.menuData.extraActions?.map((action) => {
        return (
          <Button shape="round" size="small" key={action.actionName} onClick={action.action}>
            {action.actionName}
          </Button>
        );
      })}
    >
      <Row
        gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
        style={{
          minHeight: 30,
        }}
      >
        {state.loading ? (
          <LoadingIndicator />
        ) : (
          subMenus.map((subMenuData) => {
            return (
              <Col className="gutter-row" span={8}>
                <Button
                  style={{
                    width: '100%',
                    whiteSpace: 'normal',
                    height: 'auto',
                    paddingLeft: 2,
                    paddingRight: 2,
                    paddingTop: 4,
                    paddingBottom: 4,
                  }}
                  type="link"
                  onClick={async () => {
                    await clickSubMenuOrMenuItem(subMenuData);
                  }}
                >
                  {subMenuData.menuTitle}
                </Button>
              </Col>
            );
          })
        )}
      </Row>
    </PageHeader>
  );
};

export default GridMenu;
