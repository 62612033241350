// import { useIntl } from 'umi';
import { Card, Tooltip } from 'antd';
import type { IconLinkInfo } from '@/services/LinkService';

import { Avatar } from 'antd';
// import { EllipsisOutlined } from '@ant-design/icons';

import styles from './index.less';
import type { MouseEventHandler, ReactNode } from 'react';
import quudyConfig from '@/quudyConfig';
import { LinkLogService } from '@/services/LinkLogService';

const { Meta } = Card;

type Props = {
  iconLinkInfo: IconLinkInfo;
  leftTop?: React.ReactElement;
  content?: React.ReactElement;
  disabled?: boolean;
  onclickReplaceLink?: MouseEventHandler;
  iconReplaceLink?: ReactNode;
} & React.HTMLAttributes<HTMLDivElement>;

const QuudyHomeIconLink: React.FC<Props> = (props) => {
  const propDisabled = props.disabled ?? false;
  // const dropDown =
  //   props.leftTop === undefined ? undefined : (

  //   );
  let avatar;
  if (props.iconReplaceLink !== null && props.iconReplaceLink !== undefined) {
    avatar = <Avatar icon={props.iconReplaceLink} />;
  } else if (props.iconLinkInfo.img === null || props.iconLinkInfo.img === undefined) {
    avatar = <Avatar icon={<div>{props.iconLinkInfo.linkName.substring(0, 1)}</div>} />;
  } else {
    avatar = (
      <Avatar
        src={
          quudyConfig.frontUrl +
          (props.iconLinkInfo.img.startsWith('/')
            ? props.iconLinkInfo.img
            : '/' + props.iconLinkInfo.img)
        }
      />
    );
  }
  let onClick;
  if (!propDisabled) {
    if (props.onclickReplaceLink !== null && props.onclickReplaceLink !== undefined) {
      onClick = props.onclickReplaceLink;
    } else {
      onClick = () => {
        new LinkLogService().logLinkClick(props.iconLinkInfo.linkId!);
        globalThis.open(props.iconLinkInfo.linkAddr, '_blank');
      };
    }
  }

  return (
    <Card className={styles.iconLinkCard + (propDisabled ? ' disabled' : '')} onClick={onClick}>
      <div className={styles.menuButton}>{props.leftTop}</div>
      <Meta avatar={avatar} />
      <Tooltip title={props.iconLinkInfo.linkName} placement={'topLeft'}>
        <div className={styles.iconName}>
          <span>{props.iconLinkInfo.linkName}</span>
        </div>
      </Tooltip>
    </Card>
  );
};

export default QuudyHomeIconLink;
