// import { useIntl } from 'umi';
import type { FormProps } from 'antd';
import { notification } from 'antd';
import { Card } from 'antd';
import { Button, Divider, Form, Input, Space } from 'antd';
import styles from './index.less';

// import { DragDropContext, Droppable, Draggable, DraggingStyle, NotDraggingStyle } from "react-beautiful-dnd";
import type { IconLinkInfo } from '@/services/LinkService';
import { LinkService } from '@/services/LinkService';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import QuudyHomeIconLink from '../QuudyHomeIconLink';
import { Collapse } from 'react-collapse';
// TODO 不知为何umi没有将Collapse的css样式加入进来导致无法播放动画
import type { CSSProperties } from 'react';
import { useEffect, useState } from 'react';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import { useCookies } from 'react-cookie';
import camelcaseKeys from 'camelcase-keys';
import snakecaseKeys from 'snakecase-keys';
import { ArrayUtils } from '@/services/utils/ArrayUtils';
import quudyConfig from '@/quudyConfig';
import React from 'react';

type IconLiWraperProps = {
  child: React.ReactNode;
};

const SContainer = SortableContainer((props: React.HTMLAttributes<HTMLDivElement>) => {
  return <ul>{props.children}</ul>;
});

type Props = {
  mainIconLinkInfoList: IconLinkInfo[];
  customIconLinkInfoList: IconLinkInfo[];
} & React.HTMLAttributes<HTMLDivElement>;

type CookieCustomIconInfo = {
  // customLinkId: string;
  linkId?: string;
  linkName: string;
  linkAddr: string;
  img?: string;
};

type State = {
  addMode: boolean;
  deleteMode: boolean;
};

type IconLinkForm = {
  link_id?: string | null;
  link_name?: string | null;
  link_addr?: string | null;
};

const getInitCustomIconLinkInfoList = (customIconLinkInfoList: IconLinkInfo[]) => {
  return customIconLinkInfoList.map((link) => ({
    // customLinkId: uuidv4(),
    linkId: link.linkId,
    linkName: link.linkName,
    linkAddr: link.linkAddr,
    img: link.img,
  })) as CookieCustomIconInfo[];
};

const QuudyHomeCustomSiteIcons: React.FC<Props> = (props) => {
  const prefix = quudyConfig.cookieKeyPrefix;
  const [cookies, _setCookie] = useCookies([prefix + 'linkd']);
  // const [cookies, _setCookie] = useCookies(['DEV_linkd']);
  const cookie = (name: string) => {
    return cookies[prefix + name];
  };
  const setCookie = (
    name: string,
    value: any,
    options?:
      | {
          path: string;
          domain: string;
          secure: boolean;
          sameSite: 'none';
          maxAge: number;
        }
      | undefined,
  ) => {
    _setCookie(prefix + name, value, options);
  };

  let initCustomIconLinkInfoList: CookieCustomIconInfo[];
  if (cookie('linkd') !== null && cookie('linkd') !== undefined) {
    try {
      initCustomIconLinkInfoList = camelcaseKeys(cookie('linkd'), { deep: true });
    } catch (error) {
      initCustomIconLinkInfoList = getInitCustomIconLinkInfoList(props.customIconLinkInfoList);
    }
  } else {
    initCustomIconLinkInfoList = getInitCustomIconLinkInfoList(props.customIconLinkInfoList);
  }
  const [state, setState] = useState<State>({
    addMode: false,
    deleteMode: false,
  });
  const [customIconLinkInfoList, setCustomIconLinkInfoList] = useState<CookieCustomIconInfo[]>(
    initCustomIconLinkInfoList,
  );
  useEffect(() => {
    setCookie('linkd', snakecaseKeys(customIconLinkInfoList, { deep: true }), {
      ...quudyConfig.cookieOptionsDefault,
      maxAge: 31536000, // 一年有效期
    });
  }, [customIconLinkInfoList, _setCookie]);

  const [form] = Form.useForm<IconLinkForm>();

  // TODO useBreakpoint的响应式最初有一瞬间无法识别屏幕大小造成跳动。
  const screens = useBreakpoint();
  if (screens.xs === undefined) {
    // 桌面版的useBreakPoint会先执行一遍mobile的breakpoint之后再渲染实际桌面宽度的画面
    // 渲染完xs是必有值的
    // 因此跳过，否则会发生无用的请求
    return <></>;
  }
  let isMobile: boolean;
  if (!screens.lg && !screens.xl) {
    // 大屏和超大屏以下时,标题表示在上方并且
    isMobile = true;
  } else {
    isMobile = false;
  }
  let formProp: FormProps;
  let iconLiWraperStyle: CSSProperties;
  if (!screens.lg && !screens.xl) {
    // 大屏和超大屏以下时,标题表示在上方并且
    formProp = {
      labelCol: {
        span: 24,
      },
      wrapperCol: {
        span: 24,
      },
      layout: 'vertical',
    };
    iconLiWraperStyle = {
      display: 'inline-block',
      marginRight: 5,
      marginBottom: 5,
    };
  } else {
    formProp = {
      labelCol: {
        span: 4,
      },
      wrapperCol: {
        span: 16,
      },
      layout: 'horizontal',
    };
    iconLiWraperStyle = {
      display: 'inline-block',
      marginRight: 20,
      marginBottom: 5,
    };
  }

  const IconLiWraper: React.FC<IconLiWraperProps> = (wrapperProps) => (
    <li style={iconLiWraperStyle}>{wrapperProps.child}</li>
  );
  const SItem = SortableElement(IconLiWraper);
  return (
    <div
      className={styles.QuudyHomeCustomSiteIcons}
      onClick={() => {
        if (state.deleteMode) {
          setState({
            addMode: false,
            deleteMode: false,
          });
        }
      }}
    >
      <Card>
        <ul>
          {props.mainIconLinkInfoList.map((iconLinkInfo) => (
            <IconLiWraper child={<QuudyHomeIconLink iconLinkInfo={iconLinkInfo} />} />
          ))}
        </ul>
        <Divider style={{ marginTop: 0, marginBottom: 5 }} />
        <SContainer
          distance={1}
          onSortEnd={(result) => {
            setCustomIconLinkInfoList(
              ArrayUtils.moveTo(customIconLinkInfoList, result.oldIndex, result.newIndex),
            );
          }}
          axis="xy"
          lockToContainerEdges={true}
        >
          {customIconLinkInfoList
            .map((iconLinkInfo, index) => (
              <SItem
                disabled={state.addMode}
                key={`item-${iconLinkInfo.linkId}`}
                index={index}
                child={
                  <QuudyHomeIconLink
                    disabled={state.addMode}
                    iconLinkInfo={iconLinkInfo}
                    // showDelete={state.deleteMode}
                    leftTop={
                      state.deleteMode ? (
                        <Button
                          // disabled={propDisabled}
                          size="small"
                          icon={<MinusOutlined key="ellipsis" />}
                          shape="circle"
                          style={{
                            opacity: 0.5,
                            backgroundColor: '#ff5353',
                            color: 'white',
                          }}
                          onClick={(e) => {
                            e.stopPropagation();
                            setCustomIconLinkInfoList(
                              customIconLinkInfoList.filter(
                                (item) => item.linkId !== iconLinkInfo.linkId,
                              ),
                            );
                          }}
                        />
                      ) : undefined
                    }
                    // menu={
                    //   <Menu>
                    //     <Menu.Item
                    //       key="0"
                    //       onClick={(menuInfo) => {
                    //         menuInfo.domEvent.stopPropagation();
                    //         form.setFieldsValue({
                    //           link_id: iconLinkInfo.linkId,
                    //           link_name: iconLinkInfo.linkName,
                    //           link_addr: iconLinkInfo.linkAddr,
                    //         });
                    //         setState({
                    //           addMode: true,
                    //           deleteMode: false,
                    //         });
                    //       }}
                    //     >
                    //       アイコンリンク編集
                    //     </Menu.Item>
                    //     <Menu.Item
                    //       key="1"
                    //       onClick={(menuInfo) => {
                    //         menuInfo.domEvent.stopPropagation();
                    //         setCustomIconLinkInfoList(
                    //           customIconLinkInfoList.filter(
                    //             (item) => item.linkId !== iconLinkInfo.linkId,
                    //           ),
                    //         );
                    //       }}
                    //     >
                    //       アイコンリンク削除
                    //     </Menu.Item>
                    //   </Menu>
                    // }
                  />
                }
              />
            ))
            .concat(
              state.deleteMode || state.addMode ? (
                <div />
              ) : (
                <IconLiWraper
                  child={
                    <div
                    // style={{
                    //   display: 'inline',
                    // }}
                    // className={linkStyles.iconLinkCard}
                    >
                      <div
                        style={
                          {
                            // height: 42,
                          }
                        }
                      >
                        <Button
                          type="dashed"
                          shape="default"
                          icon={<PlusOutlined />}
                          size={isMobile ? 'small' : 'large'}
                          onClick={() => {
                            setState({
                              addMode: true,
                              deleteMode: false,
                            });
                          }}
                        />
                      </div>
                      <div
                        style={{
                          height: 5,
                        }}
                      />
                      <div
                        style={
                          {
                            // height: 42,
                          }
                        }
                      >
                        <Button
                          type="dashed"
                          shape="default"
                          icon={<MinusOutlined />}
                          size={isMobile ? 'small' : 'large'}
                          onClick={() => {
                            setState({
                              addMode: false,
                              deleteMode: true,
                            });
                          }}
                        />
                      </div>
                    </div>
                    // <QuudyHomeIconLink
                    //   disabled={state.addMode}
                    //   iconLinkInfo={{
                    //     linkAddr: '/',
                    //     linkName: '追加',
                    //   }}
                    //   content={

                    //   }
                    //   iconReplaceLink={<PlusCircleOutlined />}
                    //   // onclickReplaceLink={() => {
                    //   //   form.setFieldsValue({
                    //   //     link_id: null,
                    //   //     link_name: null,
                    //   //     link_addr: null,
                    //   //   });
                    //   //   setState({
                    //   //     addMode: true,
                    //   //     deleteMode: false,
                    //   //   });
                    //   // }}
                    // />
                  }
                />
              ),
            )}
        </SContainer>
        <Collapse className={styles.ReactCollapseCollapse} isOpened={state.addMode}>
          <div>
            <Form name="icon-link-form" form={form} {...formProp}>
              <Form.Item
                name="link_name"
                label="サイト名"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="link_addr"
                label="サイトURL"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item style={{ marginBottom: 0 }}>
                <Space
                  align={'end'}
                  style={{
                    width: '100%',
                  }}
                >
                  <Button
                    onClick={() => {
                      setState({
                        addMode: false,
                        deleteMode: false,
                      });
                    }}
                  >
                    キャンセル
                  </Button>
                  {React.createElement(() => {
                    // eslint-disable-next-line react-hooks/rules-of-hooks
                    const [loading, setLoading] = useState<boolean>(false);
                    return (
                      <Button
                        loading={loading}
                        onClick={async () => {
                          setLoading(true);
                          const formValues = form.getFieldsValue();
                          const targetLink = customIconLinkInfoList.find(
                            (linkInfo) => linkInfo.linkId === formValues.link_id,
                          );
                          if (
                            !formValues.link_addr?.startsWith('http://') &&
                            !formValues.link_addr?.startsWith('https://')
                          ) {
                            form.setFieldValue('link_addr', 'https://' + formValues.link_addr);
                            formValues.link_addr = 'https://' + formValues.link_addr;
                          }
                          if (
                            // 1.全新的site
                            targetLink === undefined ||
                            // 2.url被改变了,所以需要向服务器报告
                            targetLink.linkAddr !== formValues.link_addr
                          ) {
                            const siteInfo = await new LinkService().iconUrl(
                              formValues.link_addr ?? '',
                              formValues.link_name ?? '未入力サイト名',
                            );
                            if (siteInfo === null || siteInfo === undefined) {
                              notification.error({
                                placement: 'bottomLeft',
                                message: '入力したサイトがアクセスできない。',
                              });
                            } else {
                              setCustomIconLinkInfoList(
                                customIconLinkInfoList.concat([
                                  {
                                    linkId: siteInfo.linkId!,
                                    linkName: formValues.link_name ?? '',
                                    linkAddr: formValues.link_addr ?? '',
                                    img: siteInfo.img ?? '',
                                  },
                                ]),
                              );
                            }
                          } else {
                            const newList = ([] as CookieCustomIconInfo[]).concat(
                              customIconLinkInfoList,
                            );
                            const target = newList.find(
                              (item) => item.linkId === formValues.link_id,
                            );
                            if (target !== undefined) {
                              target.linkName = formValues.link_name ?? '';
                              target.linkAddr = formValues.link_addr ?? '';
                              setCustomIconLinkInfoList(newList);
                            }
                          }
                          setState({
                            addMode: false,
                            deleteMode: false,
                          });
                          setLoading(false);
                        }}
                      >
                        完成
                      </Button>
                    );
                  })}
                </Space>
              </Form.Item>
            </Form>
          </div>
        </Collapse>
      </Card>
    </div>
  );
};

export default QuudyHomeCustomSiteIcons;
