import camelcaseKeys from 'camelcase-keys';
import { BaseService } from './BaseService';
import type { ModelServerData } from './datatypes/BaseModel';
import type { AdvModel } from './datatypes/Adv';

export class AdvService extends BaseService {
  async getAdv(): Promise<ModelServerData<AdvModel>[]> {
    const result = await super.invokeService<any>('adv', 'get_adv', {});
    return camelcaseKeys(result, { deep: true }) as ModelServerData<AdvModel>[];
  }

  /**
   * リンククリックログ
   */
  async logAdvClick(advId: string): Promise<void> {
    await super.invokeService<any>('adv', 'log_adv_click', {
      methodParams: [advId],
    });
  }
}
