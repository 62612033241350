import camelcaseKeys from 'camelcase-keys';
import { BaseService } from './BaseService';
import type { CategorizedLinksInfo } from '@/components/QuudyHomeSideTabs';
import type { ModelServerData } from './datatypes/BaseModel';
import type { BigCategoryModel } from './datatypes/BigCategory';
import type { CategoryModel } from './datatypes/Category';
import type { LinkModel } from './datatypes/Link';
// import { camelCase } from 'lodash';

export class LinkService extends BaseService {
  // async eventApplyFormInfo(): Promise<FormType> {
  //   const result = await super.invokeService<any>('event_apply', 'event_apply_form_info', {});
  //   return camelcaseKeys(result, { deep: true }) as FormType;
  // }

  async getCategorizedLinksInfo(): Promise<CategorizedLinksInfo> {
    // const modelTreeList = camelcaseKeys(
    //   await super.searchModelList({
    //     modelDescription: {
    //       modelName: 'bigCategory',
    //       childModelDescriptions: [
    //         {
    //           modelName: 'category',
    //           childModelDescriptions: [
    //             {
    //               modelName: 'link',
    //             },
    //           ],
    //         },
    //       ],
    //     },
    //     queryOptions: {
    //       returnRecordAsModelTree: true,
    //     },
    //   }),
    //   { deep: true },
    // );

    const modelTreeList = camelcaseKeys(
      (await super.invokeService<any>('link', 'get_categorized_links_info', {})) as
        | any[]
        | [any[], number],
      { deep: true },
    );
    // return camelcaseKeys(modelTreeList, { deep: true }) as CategorizedLinksInfo;
    return {
      bigCategories: modelTreeList.map((modelTreeBC) => {
        return {
          bigCategory: modelTreeBC.bigCategory as ModelServerData<BigCategoryModel>,
          categories: modelTreeBC.category.map((modelTreeC: { category: any; link: any[] }) => {
            return {
              category: modelTreeC.category as ModelServerData<CategoryModel>,
              links: modelTreeC.link.map(
                (modelTreeL) => modelTreeL.link as ModelServerData<LinkModel>,
              ),
            };
          }),
        };
      }),
    };
    // return {
    //   bigCategories: modelTreeList.map((modelTreeBC) => {
    //     return {
    //       bigCategory: camelcaseKeys(modelTreeBC.big_category) as ModelServerData<BigCategoryModel>,
    //       categories: modelTreeBC.category.map((modelTreeC: { category: any; link: any[] }) => {
    //         return {
    //           category: camelcaseKeys(modelTreeC.category) as ModelServerData<CategoryModel>,
    //           links: modelTreeC.link.map(
    //             (modelTreeL) => camelcaseKeys(modelTreeL.link) as ModelServerData<LinkModel>,
    //           ),
    //         };
    //       }),
    //     };
    //   }),
    // };
  }

  async getIconLinkInfo(): Promise<[IconLinkInfo[], IconLinkInfo[]]> {
    const result = await super.invokeService<any>('link', 'get_icon_link_info', {});
    return camelcaseKeys(result, { deep: true }) as [IconLinkInfo[], IconLinkInfo[]];
  }

  async iconUrl(url: string, siteName: string): Promise<ModelServerData<LinkModel> | undefined> {
    const result = await super.invokeService<any>('link', 'icon_url', {
      methodParams: [url, siteName],
    });
    return camelcaseKeys(result, { deep: true }) as ModelServerData<LinkModel>;
  }
}

export type IconLinkInfo = {
  linkId?: string;
  linkName: string;
  linkAddr: string;
  color?: string;
  img?: string;
  position?: string;
  orderNo?: string;
};
