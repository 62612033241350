import quudyConfig from '@/quudyConfig';
import type { FunctionComponent, ReactNode } from 'react';

import { useState } from 'react';
import { useCookies } from 'react-cookie';

type Props = {
  builder: (
    cookie: (name: string) => any,
    setCookie: (
      name: string,
      value: any,
      options?: {
        path: string;
        domain: string;
        secure: boolean;
        sameSite: 'none';
        maxAge: number;
      },
    ) => void,
    removeCookie: (
      name: string,
      options?: {
        path: string;
        domain: string;
        secure: boolean;
        sameSite: 'none';
        maxAge: number;
      },
    ) => void,
    nowCookie?: (name: string) => any,
    setNowCookie?: (nowCookies: Record<string, any>) => void,
  ) => ReactNode;
  dependencies: string[];
};

// 当cookie变化时会刷新的builder
export const CookieDependBuilder: FunctionComponent<Props> = (props) => {
  const prefix = quudyConfig.cookieKeyPrefix;
  const dependencies: string[] = props.dependencies.map((dependency) => prefix + dependency);
  const [cookies, _setCookie, _removeCookie] = useCookies(dependencies);
  const cookie = (name: string) => {
    return cookies[prefix + name];
  };
  const setCookie = (
    name: string,
    value: any,
    options?:
      | {
          path: string;
          domain: string;
          secure: boolean;
          sameSite: 'none';
          maxAge: number;
        }
      | undefined,
  ) => {
    _setCookie(prefix + name, value, options);
  };
  const removeCookie = (
    name: string,
    options?:
      | {
          path: string;
          domain: string;
          secure: boolean;
          sameSite: 'none';
          maxAge: number;
        }
      | undefined,
  ) => {
    _removeCookie(prefix + name, options);
  };

  const [nowCookies, _setNowCookies] = useState<Record<string, any>>(cookies);
  const nowCookie = (name: string) => {
    return nowCookies[prefix + name];
  };

  const setNowCookie = (newNowCookies: Record<string, any>) => {
    const keys = Object.keys(newNowCookies);
    const newNowCookiesWithPrefix: Record<string, any> = {};
    for (const key of keys) {
      newNowCookiesWithPrefix[prefix + key] = newNowCookies[key];
    }
    _setNowCookies(newNowCookiesWithPrefix);
  };
  return <>{props.builder(cookie, setCookie, removeCookie, nowCookie, setNowCookie)}</>;
};

export default CookieDependBuilder;
