import camelcaseKeys from 'camelcase-keys';
import { BaseService, BussinessLogicException } from './BaseService';
import type { ModelServerData } from './datatypes/BaseModel';
import { SUPERMARKET_LINK_CATEGORY } from './datatypes/LinkCategory';
import type { LinkCategoryModel } from './datatypes/LinkCategory';

import { ObjectUtils2 } from './utils/ObjectUtils2';
import { CompanyModel } from './datatypes/Company';

export type RecommendLink = {
  linkId: string | null;
  recommendId: string | null;
  linkName: string | null;
  linkAddr: string | null;
  img: string | null;
  description: string | null;
  companyName: string | null;
  companyImg: string | null;
  // 名字叫total实际上是会社的commentCnt
  total: string | null;
  score: string | null;
  companyId: string | null;
  linkCategoryId: string | null;
  state: RecommendLinkState | null;
  company?: CompanyModel | null;
};

export const RecommendLinkState = {
  /** コレクションにない */
  NOT_YET_COLLECT: '0',
  /** コレクションにある */
  IN_COLLECTION: '1',
} as const;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type RecommendLinkState = typeof RecommendLinkState[keyof typeof RecommendLinkState];

export class RecommendService extends BaseService {
  async getRecommendCategoryInfo(): Promise<ModelServerData<LinkCategoryModel>[]> {
    const result = await super.invokeService<any>('recommend', 'get_recommend_category_info', {
      methodParams: [],
    });
    return camelcaseKeys(result, { deep: true }) as ModelServerData<LinkCategoryModel>[];
  }

  // /**
  //  * @deprecated
  //  */
  // async getRecommendLinks(
  //   categoryId: string | number,
  //   page: number,
  // ): Promise<[RecommendLink[], number]> {
  //   const result = await super.invokeService<any>('recommend', 'get_recommend_links', {
  //     methodParams: [
  //       {
  //         category_id: categoryId,
  //         page: page,
  //       },
  //     ],
  //   });
  //   return [camelcaseKeys(result.links, { deep: true }), result.total] as [RecommendLink[], number];
  // }

  async getClientRecommendLinkInfo(
    categoryId: string | number,
    offset: number,
    limit: number,
  ): Promise<[RecommendLink[], number]> {
    const result = await super.invokeService<any>('recommend', 'get_client_recommend_link_info', {
      methodParams: [[], categoryId, offset, limit],
    });
    let linkInfo: RecommendLink[] = [];
    let count: number = 0;
    if ((result?.recommend_link?.[1] ?? 0) > 0) {
      const recommendLinks = camelcaseKeys(result.recommend_link[0], { deep: true }) as any[];
      linkInfo = recommendLinks.map((linkData) => {
        const recommendLink = linkData.recommendLink;
        const company = linkData.company;
        const linkCategory = linkData.linkCategory;
        const userCollection = linkData.userCollection;
        let description;
        if (!ObjectUtils2.isNullOrEmpty(company.companyId)) {
          if (SUPERMARKET_LINK_CATEGORY.includes(Number.parseInt(linkCategory.linkCategoryId))) {
            const address =
              (linkData.ken?.kenName ?? '') +
              (linkData.city?.cityName ?? '') +
              (linkData.town?.townName ?? '') +
              (linkData.company?.blockName != null ? linkData.company?.blockName + '丁目' : '') +
              (linkData.company?.roomName ?? '');
            description = address;
          }
          // 超市descrption是地址
        } else {
          description = recommendLink.description;
        }
        return {
          linkId: null,
          recommendId: recommendLink.recommendId,
          linkName: recommendLink.linkName,
          linkAddr: recommendLink.linkAddr,
          img: recommendLink.img,
          description: description,
          companyName: company.companyName,
          companyImg: company.companyImg,
          // 名字叫total实际上是会社的commentCnt,
          total: company.commentCnt,
          score: company.scoreAvg,
          companyId: company.companyId,
          linkCategoryId: linkCategory.linkCategoryId,
          state:
            (userCollection?.length ?? 0) > 0
              ? RecommendLinkState.IN_COLLECTION
              : RecommendLinkState.NOT_YET_COLLECT,
          company: !ObjectUtils2.isNullOrEmpty(company.companyId)
            ? new CompanyModel(company)
            : null,
        } as RecommendLink;
      });
      count = result.recommend_link[1];
    } else if ((result?.exclusive_link?.[1] ?? 0) > 0) {
      const exclusiveLinks = camelcaseKeys(result.exclusive_link[0], { deep: true }) as any[];
      linkInfo = exclusiveLinks.map((linkData) => {
        const exclusiveLink = linkData.exclusiveLink;
        const linkCategory = linkData.linkCategory;
        const userCollection = linkData.userCollection;
        return {
          linkId: exclusiveLink.linkId,
          recommendId: null,
          linkName: exclusiveLink.linkName,
          linkAddr: exclusiveLink.linkAddr,
          img: exclusiveLink.img,
          description: exclusiveLink.description,
          companyName: null,
          companyImg: null,
          // 名字叫total实际上是会社的commentCnt,
          total: null,
          score: null,
          companyId: null,
          linkCategoryId: linkCategory.linkCategoryId,
          state:
            (userCollection?.length ?? 0) > 0
              ? RecommendLinkState.IN_COLLECTION
              : RecommendLinkState.NOT_YET_COLLECT,
          company: null,
        } as RecommendLink;
      });
      count = result.exclusive_link[1];
    }

    return [camelcaseKeys(linkInfo, { deep: true }), count] as [RecommendLink[], number];
  }

  async getClientUserCollections(
    offset: number,
    limit: number,
  ): Promise<[RecommendLink[], number]> {
    const result = await super.invokeService<any>('recommend', 'get_client_user_collections', {
      methodParams: [[], offset, limit],
    });
    const collectionList: RecommendLink[] = (camelcaseKeys(result[0], { deep: true }) as any[]).map(
      (collection) => {
        if (!ObjectUtils2.isNullOrEmpty(collection.recommendLink?.recommendId)) {
          const recommendLink = collection.recommendLink;
          const company = collection.company;
          const linkCategory = collection.recommendLinkCategory;
          let description;
          if (!ObjectUtils2.isNullOrEmpty(company.companyId)) {
            if (SUPERMARKET_LINK_CATEGORY.includes(Number.parseInt(linkCategory.linkCategoryId))) {
              const address =
                (collection.ken?.kenName ?? '') +
                (collection.city?.cityName ?? '') +
                (collection.town?.townName ?? '') +
                (collection.company?.blockName != null
                  ? collection.company?.blockName + '丁目'
                  : '') +
                (collection.company?.roomName ?? '');
              description = address;
            }
            // 超市descrption是地址
          } else {
            description = recommendLink.description;
          }

          return {
            linkId: null,
            recommendId: recommendLink.recommendId,
            linkName: recommendLink.linkName,
            linkAddr: recommendLink.linkAddr,
            img: recommendLink.img,
            description: description,
            companyName: company.companyName,
            companyImg: company.companyImg,
            // 名字叫total实际上是会社的commentCnt,
            total: company.commentCnt,
            score: company.scoreAvg,
            companyId: company.companyId,
            linkCategoryId: linkCategory.linkCategoryId,
            state: RecommendLinkState.IN_COLLECTION,
            company: !ObjectUtils2.isNullOrEmpty(company.companyId)
              ? new CompanyModel(company)
              : null,
          } as RecommendLink;
        } else if (!ObjectUtils2.isNullOrEmpty(collection.exclusiveLink?.linkId)) {
          const exclusiveLink = collection.exclusiveLink;
          const linkCategory = collection.exclusiveLinkCategory;
          return {
            linkId: exclusiveLink.linkId,
            recommendId: null,
            linkName: exclusiveLink.linkName,
            linkAddr: exclusiveLink.linkAddr,
            img: exclusiveLink.img,
            description: exclusiveLink.description,
            companyName: null,
            companyImg: null,
            // 名字叫total实际上是会社的commentCnt,
            total: null,
            score: null,
            companyId: null,
            linkCategoryId: linkCategory.linkCategoryId,
            state: RecommendLinkState.IN_COLLECTION,
            company: null,
          } as RecommendLink;
        } else {
          return {
            linkId: null,
            recommendId: null,
            linkName: null,
            linkAddr: null,
            img: null,
            description: null,
            companyName: null,
            companyImg: null,
            // 名字叫total实际上是会社的commentCnt,
            total: null,
            score: null,
            companyId: null,
            linkCategoryId: null,
            state: null,
            company: null,
          } as RecommendLink;
        }
      },
    );

    return [collectionList, result[1]] as [RecommendLink[], number];
  }

  // /**
  //  * リンククリックログ
  //  */
  // async logRecommendLinkClick(recommendId: string): Promise<void> {
  //   await super.invokeService<any>('recommend', 'log_recommend_link_click', {
  //     methodParams: [recommendId],
  //   });
  // }

  // /**
  //  * リンククリックログ
  //  */
  // async logExclusiveLinkClick(linkId: string): Promise<void> {
  //   await super.invokeService<any>('recommend', 'log_exclusive_link_click', {
  //     methodParams: [linkId],
  //   });
  // }

  /**
   * おすすめリンクのコレクション追加
   */
  async addLinkOrRecToCollection(
    recommendId: string | null,
    linkId: string | null,
  ): Promise<undefined | BussinessLogicException> {
    return await super.invokeService<undefined>('recommend', 'add_link_or_rec_to_collection', {
      methodParams: [recommendId, linkId],
    });
  }

  /**
   * おすすめリンクのコレクション削除
   */
  async removeLinkOrRecToCollection(
    recommendId: string | null,
    linkId: string | null,
  ): Promise<undefined | BussinessLogicException> {
    return await super.invokeService<undefined>('recommend', 'remove_link_or_rec_to_collection', {
      methodParams: [recommendId, linkId],
    });
  }
}
