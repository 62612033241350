// import { useIntl } from 'umi';
import type { ModelServerData } from '@/services/datatypes/BaseModel';
import type { BigCategoryModel } from '@/services/datatypes/BigCategory';
import type { CategoryModel } from '@/services/datatypes/Category';
import type { LinkModel } from '@/services/datatypes/Link';
import { LinkLogService } from '@/services/LinkLogService';
import { Divider, Tabs, TabsProps } from 'antd';
import { useState } from 'react';
import styles from './index.less';

type SiteTabsState = {
  activeKey?: string;
  // mouseHover: boolean,
  folded: boolean;
};

export type CategorizedLinksInfo = {
  bigCategories: BigCategoryInfo[];
};

type BigCategoryInfo = {
  bigCategory: ModelServerData<BigCategoryModel>;
  categories: CategoryInfo[];
};

type CategoryInfo = {
  category: ModelServerData<CategoryModel>;
  links: ModelServerData<LinkModel>[];
};

type Props = {
  linksInfo: CategorizedLinksInfo;
} & React.HTMLAttributes<HTMLDivElement>;

const QuudyHomeSideTabs: React.FC<Props> = (props) => {
  const [state, setState] = useState<SiteTabsState>({
    activeKey: undefined,
    // mouseHover: false,
    folded: true,
  });

  const componentClassName = styles.quudyHomeSideTabs + (state.folded ? ' ' + styles.folded : '');
  const linksInfo = props.linksInfo;

  const items: TabsProps['items'] = linksInfo.bigCategories.map((bigCategory) => {
    const categroyBlockList = bigCategory.categories.map((category) => {
      return (
        <div key={category.category.categoryId}>
          <div
            style={{
              fontWeight: 'bold',
              fontSize: '15px',
            }}
          >
            {category.category.categoryName}
          </div>
          <Divider style={{ margin: 0 }} />
          <ul>
            {category.links.map((link) => {
              return (
                <li
                  key={link.linkId}
                  style={{
                    display: 'inline-block',
                    marginRight: 30,
                  }}
                >
                  <a
                    href={link.linkAddr ?? ''}
                    target="_blank"
                    onClick={() => {
                      new LinkLogService().logLinkClick(link.linkId ?? '');
                    }}
                  >
                    {link.linkName}
                  </a>
                </li>
              );
            })}
          </ul>
        </div>
      );
    });
    return {
      key: bigCategory.bigCategory.bigCategoryId!,
      label: (
        <div
          className={styles.quudyHomeSideTabBoundry}
          onMouseEnter={() => {
            setState({
              activeKey: bigCategory.bigCategory.bigCategoryId!,
              folded: false,
            });
          }}
        >
          <div className="quudy-home-side-tab-inner">{bigCategory.bigCategory.bigCategoryName}</div>
        </div>
      ),
      children: categroyBlockList,
    };
  });
  return (
    <div
      className={componentClassName}
      onMouseLeave={() => {
        setState((preState) => ({
          activeKey: preState.activeKey,
          folded: true,
        }));
      }}
    >
      <Tabs tabPosition={'left'} activeKey={state.activeKey} items={items} />
    </div>
  );
};

export default QuudyHomeSideTabs;
