// import { useIntl } from 'umi';
import type { ModelServerData } from '@/services/datatypes/BaseModel';
import type { BigCategoryModel } from '@/services/datatypes/BigCategory';
import type { CategoryModel } from '@/services/datatypes/Category';
import type { LinkModel } from '@/services/datatypes/Link';
import { LinkLogService } from '@/services/LinkLogService';
// import { SolutionOutlined } from '@ant-design/icons';
// import { history } from 'umi';
import { Affix, Button, Drawer, Menu, Space } from 'antd';
import { useState } from 'react';
// import styles from './index.less';
// const { TabPane } = Tabs;

type LinkAffixState = {
  folded: boolean;
};

export type CategorizedLinksInfo = {
  bigCategories: BigCategoryInfo[];
};

type BigCategoryInfo = {
  bigCategory: ModelServerData<BigCategoryModel>;
  categories: CategoryInfo[];
};

type CategoryInfo = {
  category: ModelServerData<CategoryModel>;
  links: ModelServerData<LinkModel>[];
};

type Props = {
  linksInfo: CategorizedLinksInfo;
} & React.HTMLAttributes<HTMLDivElement>;

const QuudyHomeLinkAffix: React.FC<Props> = (props) => {
  const [state, setState] = useState<LinkAffixState>({
    folded: true,
  });

  const showDrawer = () => {
    setState({
      folded: false,
    });
  };
  const onClose = () => {
    setState({
      folded: true,
    });
  };

  const linksInfo = props.linksInfo;
  return (
    <>
      <Affix
        offsetTop={5}
        style={{
          // position: 'absolute',
          marginBottom: 4,
          marginTop: -15,
        }}
      >
        <Space direction="vertical">
          <Button shape="round" onClick={showDrawer} style={{ fontSize: 11 }} size="small">
            ウェブサイト
          </Button>
        </Space>
      </Affix>
      <Drawer
        bodyStyle={{ padding: 0 }}
        placement="left"
        title="ウェブサイト"
        visible={!state.folded}
        onClose={onClose}
      >
        <Menu mode="inline">
          {linksInfo.bigCategories.map((bigCategory) => {
            return (
              <Menu.SubMenu
                key={'bigCategory-' + bigCategory.bigCategory.bigCategoryId!}
                title={bigCategory.bigCategory.bigCategoryName}
              >
                {bigCategory.categories.map((category) => (
                  <Menu.SubMenu
                    key={'category-' + category.category.categoryId}
                    title={category.category.categoryName}
                  >
                    {category.links.map((link) => (
                      <Menu.Item key={'link-' + link.linkId}>
                        <a
                          href={link.linkAddr ?? ''}
                          target="_blank"
                          onClick={() => {
                            new LinkLogService().logLinkClick(link.linkId ?? '');
                          }}
                        >
                          {link.linkName}
                        </a>
                      </Menu.Item>
                    ))}
                  </Menu.SubMenu>
                ))}
              </Menu.SubMenu>
            );
          })}
        </Menu>
      </Drawer>
    </>
  );
};

export default QuudyHomeLinkAffix;
