import type { ModelServerData } from '@/services/datatypes/BaseModel';
import { RESTAURANT_LINK_CATEGORY } from '@/services/datatypes/LinkCategory';
import type { LinkCategoryModel } from '@/services/datatypes/LinkCategory';
import type { RecommendLink } from '@/services/RecommendService';
import { RecommendLinkState, RecommendService } from '@/services/RecommendService';
import { EnvironmentOutlined, RollbackOutlined, StarFilled, StarOutlined } from '@ant-design/icons';
import { Button, Card, Carousel, List, Modal, notification, Row } from 'antd';
import type { CarouselRef } from 'antd/lib/carousel';
import React, { useContext } from 'react';
import type { RefObject, ReactNode, FunctionComponent } from 'react';
import VirtualList from 'rc-virtual-list';
import { ObjectUtils } from '@/services/utils/ObjectUtils';
import { Link, history } from 'umi';
import styles from './index.less';
import { QuudyUserInfoContext } from '../QuudyPageLayout';
import type { QuudyUserInfo } from '@/services/UserAccountService';
import type { QuudyCompanyInfo } from '@/services/CompanyAccountService';
import { ObjectUtils2 } from '@/services/utils/ObjectUtils2';
import { LinkLogService } from '@/services/LinkLogService';
import { ModelMetaDataUtil } from '@/services/utils/ModelMetaDataUtil';
import type { ResourceUrlTypeInfo } from '@/services/datatypes/types';
import AddressSelect from '../AddressSelect';
import { LinkService } from '@/services/LinkService';
import PromiseBuilder from '../PromiseBuilder';
import QuudyHomeLinkAffix from '../QuudyHomeLinkAffix';
import RatingBar from '../RatingBar';
// import PromiseBuilder from '../PromiseBuilder';
// import QuudyHomeLinkAffix from '../QuudyHomeLinkAffix';
// import { LinkService } from '@/services/LinkService';
// import { ObjectUtils2 } from '@/services/utils/ObjectUtils2';
// import { ModelMetaDataUtil } from '@/services/utils/ModelMetaDataUtil';
// import { ResourceUrlTypeInfo } from '@/services/datatypes/types';
// import { ModelMetaDataUtil } from '@/services/utils/ModelMetaDataUtil';
// import { useCookies } from 'react-cookie';
// import type { EnumTypeInfo } from '@/services/datatypes/types';

type Props = {
  recommendLinkCategoryList: ModelServerData<LinkCategoryModel>[];
  mobile: boolean;
} & React.HTMLAttributes<HTMLDivElement>;

type InnerProps = Props & {
  quudyUserInfo?: QuudyUserInfo;
  quudyCompanyInfo?: QuudyCompanyInfo;
  displayAreaName?: string;
};

const PAGE_RECOMMEND = 0;
const PAGE_LIST = 1;

const SCROLLPART_HEIGHT = 500;

const GRID_NUM_PER_LINE = 4;

const CARD_BODY_PADDING_TB_MOBILE = 5;
const CARD_BODY_PADDING_TB = 24;

const GRID_MARGIN_NUMBER = 4;
const GRID_MARGIN = GRID_MARGIN_NUMBER + 'px';
const GRID_MARGIN_LR = GRID_MARGIN_NUMBER / 2 + 'px';
const GRID_PADDING = '5px';

const GRID_WITH = 'calc(' + (100 / GRID_NUM_PER_LINE).toString() + '%' + ' - ' + GRID_MARGIN + ')';
// const GRID_HEIGHT =
//   'calc((' + (100 / GRID_NUM_PER_LINE).toString() + '%' + ' - ' + GRID_MARGIN + ') * 3 /4)';
const GRID_HEIGHT = 77;

// const GRID_HEIGHT = '100px';

const CATEGORY_ID_COLLECTION = 'CATEGORY_ID_COLLECTION';

const CATEGORY_NAME_COLLECTION = 'お気に入り';

type State = {
  categoryId: string | number | typeof CATEGORY_ID_COLLECTION | null;
  categoryName: string | typeof CATEGORY_NAME_COLLECTION | null;
  // linkListInitialized: boolean,
  initLinkList: RecommendLink[] | null;
  pageNo: number;
  totalCount: number | null;
  gridSpaceHeight: number;
};

const initState = {
  categoryId: null,
  categoryName: null,
  initLinkList: null,
  pageNo: 0,
  totalCount: null,
  gridSpaceHeight: SCROLLPART_HEIGHT,
};

class QuudyHomeRecommendBlockInner extends React.Component<InnerProps, State> {
  constructor(props: Props) {
    super(props);
    // const girdLineCount = Math.ceil(props.recommendLinkCategoryList.length / GRID_NUM_PER_LINE);
    // const expectedHeight =
    //   girdLineCount * (GRID_HEIGHT + GRID_MARGIN_NUMBER * 2) + CARD_BODY_PADDING * 2;
    const initStateNow = ObjectUtils.clone(initState) as State;
    // if (expectedHeight < SCROLLPART_HEIGHT) {
    //   initStateNow.gridSpaceHeight = expectedHeight;
    // }
    initStateNow.gridSpaceHeight = this.expectedGridHeight();
    this.state = initStateNow;
    this.carouselRef = React.createRef<CarouselRef>();
  }

  private expectedGridHeight = () => {
    const girdLineCount = Math.ceil(
      this.props.recommendLinkCategoryList.length / GRID_NUM_PER_LINE,
    );
    const expectedHeight =
      girdLineCount * (GRID_HEIGHT + GRID_MARGIN_NUMBER * 2) +
      (this.props.mobile ? CARD_BODY_PADDING_TB_MOBILE : CARD_BODY_PADDING_TB) * 2;
    if (expectedHeight < SCROLLPART_HEIGHT) {
      return expectedHeight;
    } else {
      return SCROLLPART_HEIGHT;
    }
  };

  private carouselRef: RefObject<CarouselRef>;

  private loadNextPageData = async () => {
    const nextPageNo = this.state.pageNo + 1;
    const recommendService = new RecommendService();
    let dataList, totalCount;
    if (this.state.categoryId === CATEGORY_ID_COLLECTION) {
      // 代表是收藏页
      [dataList, totalCount] = await recommendService.getClientUserCollections(
        (nextPageNo - 1) * 20,
        20,
      );
    } else if (
      typeof this.state.categoryId === 'string' ||
      typeof this.state.categoryId === 'number'
    ) {
      // 代表是おすすめ页
      [dataList, totalCount] = await recommendService.getClientRecommendLinkInfo(
        this.state.categoryId,
        (nextPageNo - 1) * 20,
        20,
      );
    } else {
      throw new Error(
        '预期之外的categoryId(' +
          this.state.categoryId +
          ')，是不是没有设定categoryId就试图加载数据?',
      );
    }

    this.setState({
      initLinkList: (this.state.initLinkList ?? []).concat(dataList),
      pageNo: nextPageNo,
      totalCount: totalCount,
    });
  };

  private renderRecommendCard = () => {
    // const sliceByNumber = (array: any[], number: number) => {
    //   const length = Math.ceil(array.length / number)
    //   return new Array(length).fill(null).map((_, i) =>
    //     array.slice(i * number, (i + 1) * number)
    //   )
    // }
    // const categoryLines = sliceByNumber(this.props.recommendLinkCategoryList, GRID_NUM_PER_LINE);
    // const { initialState } = useModel('@@initialState');
    // const currentUser = this.props.currentUser;
    // debugger
    return (
      <div>
        <Card
          className={'category-card'}
          title={
            this.props.mobile ? (
              <span>おすすめ</span>
            ) : (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'start',
                }}
              >
                {
                  <AddressSelect
                    showButtonName={false}
                    style={{
                      display: 'inline-block',
                    }}
                  />
                }
                <span>{this.props.displayAreaName}のおすすめ</span>
              </div>
            )
          }
          style={{
            height: this.props.mobile
              ? 43 + this.state.gridSpaceHeight
              : 66 + this.state.gridSpaceHeight,
          }}
          bodyStyle={{
            height: this.state.gridSpaceHeight,
          }}
          extra={
            this.props.quudyCompanyInfo !== undefined ? undefined : (
              <Button
                shape="round"
                icon={<StarOutlined />}
                onClick={() => {
                  // if (this.props.quudyCompanyInfo !== undefined) {
                  //   Modal.info({
                  //     title: 'お気に入り　を表示しない',
                  //   });
                  // } else
                  if (this.props.quudyUserInfo === undefined) {
                    Modal.confirm({
                      title: 'ログインしてください',
                      onOk() {
                        // globalThis.location.href = '/login/';
                        history.push('/login/');
                      },
                    });
                  } else {
                    this.setState(
                      Object.assign<State, any>(ObjectUtils.clone(initState) as State, {
                        categoryId: CATEGORY_ID_COLLECTION,
                        categoryName: CATEGORY_NAME_COLLECTION,
                        gridSpaceHeight: SCROLLPART_HEIGHT,
                      }),
                      () => {
                        this.carouselRef.current?.goTo(PAGE_LIST);
                        this.loadNextPageData();
                      },
                    );
                  }
                }}
              >
                お気に入り
              </Button>
            )
          }
        >
          {this.props.recommendLinkCategoryList.map((linkCategory) => (
            <Card.Grid
              hoverable={false}
              key={linkCategory.linkCategoryId}
              onClick={async () => {
                this.setState(
                  Object.assign<State, any>(ObjectUtils.clone(initState) as State, {
                    categoryId: linkCategory.linkCategoryId,
                    categoryName: linkCategory.linkCategoryName,
                    gridSpaceHeight: SCROLLPART_HEIGHT,
                  }),
                  () => {
                    this.carouselRef.current?.goTo(PAGE_LIST);
                    this.loadNextPageData();
                  },
                );
              }}
              style={{
                width: GRID_WITH,
                height: GRID_HEIGHT,
                padding: GRID_PADDING,
                marginRight: GRID_MARGIN_LR,
                marginLeft: GRID_MARGIN_LR,
                marginTop: GRID_MARGIN,
                marginBottom: GRID_MARGIN,
                // aspectRatio: '4/3',
                textAlign: 'center',
                cursor: 'pointer',
                // borderRadius: '10px',
                backgroundColor: '#f8fbff',
              }}
            >
              <div
                style={{
                  width: '100%',
                  height: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  flexDirection: 'column',
                }}
              >
                {linkCategory.linkCategoryName}
              </div>
            </Card.Grid>
            // </div>
          ))}
          {/* </div> */}
        </Card>
      </div>
    );
  };

  private onScroll = (e: React.UIEvent<HTMLElement, UIEvent>) => {
    const target = e.target as HTMLElement;
    if (target.scrollHeight - target.scrollTop === target.clientHeight) {
      if (
        this.state.initLinkList === null ||
        this.state.initLinkList.length < this.state.totalCount!
      ) {
        this.loadNextPageData();
      }
    }
  };

  private renderRecommendListCardTitle(): ReactNode {
    return this.state.categoryId === CATEGORY_ID_COLLECTION ? (
      <span>
        <StarOutlined />
        {this.state.categoryName}
      </span>
    ) : (
      <span>
        <EnvironmentOutlined />
        {this.props.displayAreaName}のおすすめ{this.state.categoryName}
      </span>
    );
  }

  // TODO 考虑会社的情况
  private renderRecommendListCard = () => {
    // const currentUser = this.props.currentUser;
    return (
      <Card
        className={'list-card'}
        title={this.props.mobile ? '' : this.renderRecommendListCardTitle()}
        extra={
          <Button
            shape="round"
            icon={<RollbackOutlined />}
            onClick={() => {
              this.setState(
                Object.assign<State, any>(ObjectUtils.clone(initState) as State, {
                  gridSpaceHeight: this.expectedGridHeight(),
                }),
                () => {
                  this.carouselRef.current?.goTo(PAGE_RECOMMEND);
                },
              );
            }}
          >
            戻る
          </Button>
        }
      >
        <List
          loading={this.state.initLinkList === null}
          style={{
            height: SCROLLPART_HEIGHT,
          }}
        >
          <VirtualList
            key="list-card-inner-list"
            data={this.state.initLinkList!}
            height={SCROLLPART_HEIGHT}
            itemKey={(item) => {
              return 'r-' + (item.recommendId ?? 'norec') + '-' + 'l-' + (item.linkId ?? 'nolink');
            }}
            onScroll={this.onScroll}
          >
            {(item) => {
              // TODO 好像有重复渲染的问题,尝试debuger的时候渲染回数有点多
              // TODO VirtualList好像会给子组件传ref，当子组件是函数式组件的时候会出问题。
              // TODO 问题比较多可能尝试替换这个组件库比较好
              // if (item.companyId !== undefined || ) {
              // }
              const companyImgTypeInfo: ResourceUrlTypeInfo =
                ModelMetaDataUtil.getModelFieldTypeTypeInfo(
                  'company',
                  'companyImg',
                ) as ResourceUrlTypeInfo;

              // const Wrapper = Link;
              // const LinkProps = ObjectUtils2.isNullOrEmpty(item.companyId)
              //   ? {
              //       to: item.linkAddr ?? '/',
              //       // target: '_blank',
              //       onClick: () => {
              //         if (!ObjectUtils2.isNullOrEmpty(item.linkId)) {
              //           new LinkLogService().logExclusiveLinkClick(item.linkId!);
              //         } else if (!ObjectUtils2.isNullOrEmpty(item.recommendId)) {
              //           new LinkLogService().logRecommendLinkClick(item.recommendId!);
              //         }
              //       },
              //     }
              //   : {
              //       to: '/shopdetail/' + item.companyId,
              //       onClick: () => {
              //         if (!ObjectUtils2.isNullOrEmpty(item.linkId)) {
              //           new LinkLogService().logExclusiveLinkClick(item.linkId!);
              //         } else if (!ObjectUtils2.isNullOrEmpty(item.recommendId)) {
              //           new LinkLogService().logRecommendLinkClick(item.recommendId!);
              //         }
              //       },
              //     };
              let descriptionRow;
              if (
                !ObjectUtils2.isNullOrEmpty(item.company) &&
                item.linkCategoryId != null &&
                item.linkCategoryId != undefined &&
                RESTAURANT_LINK_CATEGORY.includes(Number.parseInt(item.linkCategoryId))
              ) {
                descriptionRow = (
                  <Row>
                    <RatingBar companyModel={item.company!} />
                  </Row>
                );
              } else {
                descriptionRow = <Row>{item.description}</Row>;
              }

              const linkItem = (
                <List.Item
                  key={
                    'r-' + (item.recommendId ?? 'norec') + '-' + 'l-' + (item.linkId ?? 'nolink')
                  }
                  actions={[
                    this.props.quudyCompanyInfo !== undefined ? undefined : (
                      <Button
                        style={{
                          marginRight: '5px',
                        }}
                        shape="circle"
                        icon={
                          item.state === RecommendLinkState.IN_COLLECTION ? (
                            <StarFilled />
                          ) : (
                            <StarOutlined />
                          )
                        }
                        onClick={async (e) => {
                          e.preventDefault();
                          const service = new RecommendService();
                          if (item.state === RecommendLinkState.IN_COLLECTION) {
                            const serviceError = await service.removeLinkOrRecToCollection(
                              item.recommendId,
                              item.linkId,
                            );
                            if (serviceError == undefined) {
                              if (this.state.categoryId === CATEGORY_ID_COLLECTION) {
                                this.setState({
                                  initLinkList: this.state.initLinkList!.filter(
                                    (link) =>
                                      item.linkId !== link.linkId ||
                                      item.recommendId !== link.recommendId,
                                  ),
                                });
                              } else {
                                const newList = ([] as RecommendLink[]).concat(
                                  this.state.initLinkList!,
                                );
                                const itemNew = newList.find(
                                  (link) =>
                                    item.linkId === link.linkId &&
                                    item.recommendId === link.recommendId,
                                );
                                itemNew!.state = RecommendLinkState.NOT_YET_COLLECT;
                                this.setState({
                                  initLinkList: newList,
                                });
                              }
                              notification.info({
                                placement: 'bottomLeft',
                                message: 'お気に入り',
                                description: 'リンクを削除しました',
                              });
                            }
                          } else {
                            // if (this.props.quudyCompanyInfo !== undefined) {
                            //   Modal.info({
                            //     title: 'お気に入り　を表示しない',
                            //   });
                            // } else
                            if (this.props.quudyUserInfo === undefined) {
                              // debugger;
                              Modal.confirm({
                                title: 'ログインしてください',
                                onOk() {
                                  // globalThis.location.href = '/login/';
                                  history.push('/login/');
                                },
                              });
                            } else {
                              const serviceError = await service.addLinkOrRecToCollection(
                                item.recommendId,
                                item.linkId,
                              );
                              if (serviceError == undefined) {
                                const newList = ([] as RecommendLink[]).concat(
                                  this.state.initLinkList!,
                                );
                                const itemNew = newList.find(
                                  (link) =>
                                    item.linkId === link.linkId &&
                                    item.recommendId === link.recommendId,
                                );
                                itemNew!.state = RecommendLinkState.IN_COLLECTION;
                                this.setState({
                                  initLinkList: newList,
                                });
                                notification.info({
                                  placement: 'bottomLeft',
                                  message: 'お気に入り',
                                  description: 'リンクを追加しました',
                                });
                              }
                            }
                          }
                        }}
                      />
                    ),
                  ]}
                >
                  <List.Item.Meta
                    avatar={
                      <img
                        style={{
                          // background: 'red',
                          width: '70px',
                          // height: '70px',
                        }}
                        src={
                          (ObjectUtils2.isNullOrEmpty(item.companyId)
                            ? item.img
                            : ObjectUtils2.isNullOrEmpty(item.companyImg)
                            ? null
                            : companyImgTypeInfo.baseUrl + item.companyImg) ?? '/img/ai_1.png'
                        }
                      />
                    }
                    title={
                      ObjectUtils2.isNullOrEmpty(item.companyId)
                        ? // <a
                          //   href={item.linkAddr ?? undefined}
                          //   target="_blank"
                          //   onClick={() => {
                          //     if (!ObjectUtils2.isNullOrEmpty(item.linkId)) {
                          //       new LinkLogService().logExclusiveLinkClick(item.linkId!);
                          //     } else if (!ObjectUtils2.isNullOrEmpty(item.recommendId)) {
                          //       new LinkLogService().logRecommendLinkClick(item.recommendId!);
                          //     }
                          //   }}
                          // >
                          item.linkName
                        : // </a>
                          // <Link
                          //   to={'/shopdetail/' + item.companyId}
                          //   onClick={() => {
                          //     if (!ObjectUtils2.isNullOrEmpty(item.linkId)) {
                          //       new LinkLogService().logExclusiveLinkClick(item.linkId!);
                          //     } else if (!ObjectUtils2.isNullOrEmpty(item.recommendId)) {
                          //       new LinkLogService().logRecommendLinkClick(item.recommendId!);
                          //     }
                          //   }}
                          // >
                          item.companyName
                      // </Link>
                    }
                    description={
                      <>
                        {/* <Row>{item.description}</Row>
                        {item.score === undefined || item.score === null ? undefined : (
                          <Row>
                            <span>
                              {item.score}{' '}
                              <Rate value={Number.parseFloat(item.score)} allowHalf disabled />
                            </span>
                          </Row>
                        )} */}
                        {descriptionRow}
                        {ObjectUtils2.isNullOrEmpty(item.companyId) ? undefined : (
                          <Row>
                            <span>クチコミ({item.total ?? '0'})件</span>
                          </Row>
                        )}
                      </>
                    }
                  />
                </List.Item>
              );
              return ObjectUtils2.isNullOrEmpty(item.companyId) ? (
                <a
                  href={item.linkAddr ?? undefined}
                  target="_blank"
                  onClick={() => {
                    if (!ObjectUtils2.isNullOrEmpty(item.linkId)) {
                      new LinkLogService().logExclusiveLinkClick(item.linkId!);
                    } else if (!ObjectUtils2.isNullOrEmpty(item.recommendId)) {
                      new LinkLogService().logRecommendLinkClick(item.recommendId!);
                    }
                  }}
                >
                  {linkItem}
                </a>
              ) : (
                <Link
                  to={'/shopdetail/' + item.companyId}
                  onClick={() => {
                    if (!ObjectUtils2.isNullOrEmpty(item.linkId)) {
                      new LinkLogService().logExclusiveLinkClick(item.linkId!);
                    } else if (!ObjectUtils2.isNullOrEmpty(item.recommendId)) {
                      new LinkLogService().logRecommendLinkClick(item.recommendId!);
                    }
                  }}
                >
                  {linkItem}
                </Link>
              );
            }}
          </VirtualList>
        </List>
      </Card>
    );
  };

  render() {
    return (
      // Carousel只是单纯为了实现滚动翻页特效。
      <Card
        className={styles.QuudyHomeRecommendBlock}
        bodyStyle={{
          padding: 0,
          height: (this.props.mobile ? 83 : 66) + this.state.gridSpaceHeight,
        }}
      >
        {this.props.mobile ? (
          <div
            style={{
              height: '40px',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              paddingRight: '10px',
            }}
          >
            <AddressSelect showButtonName={true} />
            <PromiseBuilder
              key={'linkAffixBlock'}
              promiseGet={function (): Promise<any> {
                // 链接
                const linkService = new LinkService();
                return linkService.getCategorizedLinksInfo();
              }}
              builder={(data) => {
                return <QuudyHomeLinkAffix linksInfo={data} />;
              }}
              loadingAreaStyle={{
                display: 'none',
              }}
            />
          </div>
        ) : undefined}
        <Carousel
          swipeToSlide={false}
          touchMove={false}
          dots={false}
          ref={this.carouselRef}
          speed={0}
        >
          {this.renderRecommendCard()}
          {this.renderRecommendListCard()}
        </Carousel>
      </Card>
    );
  }
}

// 为了给class组件hook信息而做的wrapper
const QuudyHomeRecommendBlock: FunctionComponent<Props> = (props) => {
  const quudyUserContext = useContext(QuudyUserInfoContext)!;
  const selectedAddressInfo = quudyUserContext?.selectedAddress;
  const displayAreaName =
    selectedAddressInfo?.town?.townName.value ??
    selectedAddressInfo?.city?.cityName.value ??
    selectedAddressInfo?.ken.kenName.value ??
    '';
  return (
    <QuudyHomeRecommendBlockInner
      recommendLinkCategoryList={props.recommendLinkCategoryList}
      quudyUserInfo={quudyUserContext?.quudyUserInfo}
      quudyCompanyInfo={quudyUserContext?.quudyCompanyInfo}
      displayAreaName={displayAreaName}
      mobile={props.mobile}
    />
  );
};

export default QuudyHomeRecommendBlock;
