export class ArrayUtils {
  static sliceByNumber = (array: any[], number: number) => {
    const length = Math.ceil(array.length / number);
    return new Array(length).fill(null).map((_, i) => array.slice(i * number, (i + 1) * number));
  };

  static moveTo = (array: any[], fromIndex: number, toIndex: number) => {
    if (fromIndex > array.length) {
      throw new Error('ArrayUtils.moveTo:startIndex is out of range');
    }
    if (toIndex > array.length) {
      throw new Error('ArrayUtils.moveTo:toIndex is out of range');
    }
    const newArray = ([] as any[]).concat(array);
    if (fromIndex === toIndex) {
      return newArray;
    } else {
      const splicedElem = newArray.splice(fromIndex, 1);
      const toIndexForSplicedArray = fromIndex > toIndex ? toIndex : toIndex - 1;
      return newArray
        .slice(0, toIndexForSplicedArray)
        .concat(splicedElem)
        .concat(newArray.slice(toIndexForSplicedArray));
    }
  };

  static range = (startAt = 0, endAt: number) => {
    return [...Array(endAt - startAt + 1).keys()].map((i) => i + startAt);
  };
}
