// import { useIntl } from 'umi';
import { Button, Card, Tabs } from 'antd';

import { Typography } from 'antd';
import type { EventPerKindNumberInfo } from '@/services/EventService';
import EventTitleList from '../EventTitleList';
import { EventKind } from '@/services/datatypes/Kind';
// import { EventModel } from '@/services/datatypes/Event';
import type { ReactNode } from 'react';
import { useContext } from 'react';
import { history } from 'umi';
import { QuudyUserInfoContext } from '../QuudyPageLayout';
import { ObjectUtils2 } from '@/services/utils/ObjectUtils2';
import { ModelMetaDataUtil } from '@/services/utils/ModelMetaDataUtil';
import type { EnumTypeInfo } from '@/services/datatypes/types';
import { SolutionOutlined } from '@ant-design/icons';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
// import { DateUtils } from '@/services/utils/DateUtils';
// import { useModel } from 'umi';
// import { ModelMetaDataUtil } from '@/services/utils/ModelMetaDataUtil';

const { Title, Text } = Typography;
const { TabPane } = Tabs;

type Props = {
  initEventInfo: EventPerKindNumberInfo;
  mobile: boolean;
} & React.HTMLAttributes<HTMLDivElement>;

// export type InitEventInfo = {
//   /** 管理組合 */
//   /** 町会 */
//   eventsKindManagementAssociation: EventListInfo;
//   // eventsKindNeighborhood: EventListInfo,
//   /** 育児 */
//   eventsKindChildCare: EventListInfo;
//   /** 学生 */
//   eventsKindStudent: EventListInfo;
//   /** 会社員 */
//   eventsKindOfficeFair: EventListInfo;
//   /** お年寄り */
//   eventsKindElderly: EventListInfo;
//   /** フードシェア */
//   eventsKindFoodShare: EventListInfo;
//   /** 代行 */
//   eventsKindActOnOnesBehalf: EventListInfo;
//   /** お知らせ */
//   eventsKindInform: EventListInfo;
//   /** 無料 */
//   eventsKindFree: EventListInfo;
//   /** キャンペーン */
//   eventsKindCampaign: EventListInfo;
//   /** 募集 */
//   eventsKindRecruitment: EventListInfo;
//   /** 有料 */
//   eventsKindPaid: EventListInfo;
//   /** その他 */
//   eventsKindOther: EventListInfo;
// };

const QuudyHomeEventBlock: React.FC<Props> = (props) => {
  // const { initialState } = useModel('@@initialState');
  // ModelMetaDataUtil.initByData(initialState!.modelMetaData!);
  const quudyUserContext = useContext(QuudyUserInfoContext)!;
  const screens = useBreakpoint();
  let isMobile: boolean;
  if (!screens.lg && !screens.xl) {
    // 大屏和超大屏以下时,标题表示在上方并且
    isMobile = true;
  } else {
    isMobile = false;
  }
  const selectedAddressInfo = quudyUserContext?.selectedAddress;
  const selectedAreaName =
    selectedAddressInfo?.town?.townName.value ??
    selectedAddressInfo?.city?.cityName.value ??
    selectedAddressInfo?.ken.kenName.value ??
    '';
  const userInfo = quudyUserContext.quudyUserInfo ?? quudyUserContext.quudyNeighborhoodInfo;
  const userAreaName =
    userInfo?.[1].town?.[0].townName.value ??
    userInfo?.[1].city?.[0].cityName.value ??
    userInfo?.[1].ken?.[0].kenName.value;
  let displayAreaName;
  if (userAreaName !== undefined && userAreaName !== null) {
    const displayBlockName = ObjectUtils2.isNullOrEmpty(userInfo?.[0].blockName.value)
      ? ''
      : userInfo?.[0].blockName.value + '丁目';
    displayAreaName = userAreaName + displayBlockName;
  } else {
    displayAreaName = selectedAreaName;
  }

  const mansionName = quudyUserContext.quudyUserInfo?.[1].mansion?.[0].mansionName.value;
  if (!ObjectUtils2.isNullOrEmpty(mansionName)) {
    displayAreaName = mansionName;
  }

  const kindInfoMap = {} as any;
  for (const kindInfo of props.initEventInfo) {
    // @ts-ignore
    kindInfoMap[kindInfo.kindId] = kindInfo;
  }
  const eventKindTypeInfo = ModelMetaDataUtil.getModelFieldTypeTypeInfo(
    'event',
    'kindId',
  ) as EnumTypeInfo;

  // 揭载 板块
  let blockEvent = <div />;
  if (
    // props.initEventInfo.eventsKindManagementAssociation.eventList.length > 0 ||
    // props.initEventInfo.eventsKindChildCare.eventList.length > 0 ||
    // props.initEventInfo.eventsKindStudent.eventList.length > 0 ||
    // props.initEventInfo.eventsKindOfficeFair.eventList.length > 0 ||
    // props.initEventInfo.eventsKindElderly.eventList.length > 0 ||
    // props.initEventInfo.eventsKindFoodShare.eventList.length > 0 ||
    // props.initEventInfo.eventsKindActOnOnesBehalf.eventList.length > 0
    // 管理組合
    Number.parseInt(kindInfoMap[EventKind.KIND_MANAGEMENT_ASSOCIATION]?.kindEventNum ?? '0') > 0 ||
    // 町会役所
    Number.parseInt(kindInfoMap[EventKind.KIND_NEIGHBORHOOD_INFORM]?.kindEventNum ?? '0') > 0 ||
    Number.parseInt(kindInfoMap[EventKind.KIND_NEIGHBORHOOD_EVENT]?.kindEventNum ?? '0') > 0 ||
    Number.parseInt(kindInfoMap[EventKind.KIND_NEIGHBORHOOD_SERVICE]?.kindEventNum ?? '0') > 0 ||
    Number.parseInt(kindInfoMap[EventKind.KIND_NEIGHBORHOOD_RECRUITMENT]?.kindEventNum ?? '0') >
      0 ||
    // life square
    Number.parseInt(kindInfoMap[EventKind.KIND_ACTIVITIES]?.kindEventNum ?? '0') > 0 ||
    Number.parseInt(kindInfoMap[EventKind.KIND_CHILDCARE]?.kindEventNum ?? '0') > 0 ||
    Number.parseInt(kindInfoMap[EventKind.KIND_COMPROMISE]?.kindEventNum ?? '0') > 0 ||
    Number.parseInt(kindInfoMap[EventKind.KIND_MUTUAL_HELP]?.kindEventNum ?? '0') > 0 ||
    Number.parseInt(kindInfoMap[EventKind.KIND_LIFE_HINT]?.kindEventNum ?? '0') > 0 ||
    // Number.parseInt(kindInfoMap[EventKind.KIND_FOOD_SHARE]?.kindEventNum ?? '0') > 0 ||
    // Number.parseInt(kindInfoMap[EventKind.KIND_ACT_ON_ONES_BEHALF]?.kindEventNum ?? '0') > 0 ||
    Number.parseInt(kindInfoMap[EventKind.KIND_OTHER_OF_LIFE_SQUARE]?.kindEventNum ?? '0') > 0 ||
    // 法人投稿
    Number.parseInt(kindInfoMap[EventKind.KIND_INFORM]?.kindEventNum ?? '0') > 0 ||
    Number.parseInt(kindInfoMap[EventKind.KIND_FREE]?.kindEventNum ?? '0') > 0 ||
    Number.parseInt(kindInfoMap[EventKind.KIND_CAMPAIGN]?.kindEventNum ?? '0') > 0 ||
    Number.parseInt(kindInfoMap[EventKind.KIND_RECRUITMENT]?.kindEventNum ?? '0') > 0 ||
    Number.parseInt(kindInfoMap[EventKind.KIND_PAID]?.kindEventNum ?? '0') > 0 ||
    Number.parseInt(kindInfoMap[EventKind.KIND_OTHER]?.kindEventNum ?? '0') > 0
  ) {
    // 当存在新种类揭载的时候表示

    // 管理組合＆町会揭载 板块
    let blockManagementAssociation = <div />;
    if (
      // props.initEventInfo.eventsKindManagementAssociation.eventList.length > 0
      Number.parseInt(kindInfoMap[EventKind.KIND_MANAGEMENT_ASSOCIATION]?.kindEventNum ?? '0') > 0
    ) {
      // 当存在管理組合＆町会揭载的时候表示
      blockManagementAssociation = (
        <div>
          <Title
            level={4}
            style={{
              marginTop: 0,
              marginBottom: isMobile ? 10 : 0,
              marginLeft: 0,
              marginRight: 0,
              textAlign: isMobile ? 'center' : undefined,
            }}
          >
            管理組合
          </Title>
          <EventTitleList
            targetEventKinds={[EventKind.KIND_MANAGEMENT_ASSOCIATION]}
            // initEventListInfo={props.initEventInfo.eventsKindManagementAssociation}
          />
        </div>
      );
    }

    // 町会・役所 板块
    let blockNeighborhoods = <div />;
    if (
      // props.initEventInfo.eventsKindChildCare.eventList.length > 0 ||
      // props.initEventInfo.eventsKindStudent.eventList.length > 0 ||
      // props.initEventInfo.eventsKindOfficeFair.eventList.length > 0 ||
      // props.initEventInfo.eventsKindElderly.eventList.length > 0
      Number.parseInt(kindInfoMap[EventKind.KIND_NEIGHBORHOOD_INFORM]?.kindEventNum ?? '0') > 0 ||
      Number.parseInt(kindInfoMap[EventKind.KIND_NEIGHBORHOOD_EVENT]?.kindEventNum ?? '0') > 0 ||
      Number.parseInt(kindInfoMap[EventKind.KIND_NEIGHBORHOOD_SERVICE]?.kindEventNum ?? '0') > 0 ||
      Number.parseInt(kindInfoMap[EventKind.KIND_NEIGHBORHOOD_RECRUITMENT]?.kindEventNum ?? '0') > 0
    ) {
      const kindsExists: EventKind[] = [];
      // const allLifeSqureKindsEventInfo: EventListInfo = {
      //   eventList: [],
      //   totalCount: 0,
      // };
      const allNeighborhoodKindsEventInfoNumber =
        Number.parseInt(kindInfoMap[EventKind.KIND_NEIGHBORHOOD_INFORM]?.kindEventNum ?? '0') +
        Number.parseInt(kindInfoMap[EventKind.KIND_NEIGHBORHOOD_EVENT]?.kindEventNum ?? '0') +
        Number.parseInt(kindInfoMap[EventKind.KIND_NEIGHBORHOOD_SERVICE]?.kindEventNum ?? '0') +
        Number.parseInt(kindInfoMap[EventKind.KIND_NEIGHBORHOOD_RECRUITMENT]?.kindEventNum ?? '0');

      const tabPanes = [];

      function addKindTab(targetKindId: EventKind, targetKindName: string) {
        kindsExists.push(targetKindId);
        // const eventFirst = new EventModel(initEventList.eventList[0]);
        tabPanes.push(
          <TabPane tab={targetKindName} key={targetKindId}>
            <EventTitleList
              targetEventKinds={[targetKindId]}
              // initEventListInfo={initEventList}
            />
          </TabPane>,
        );

        // すべて表示时的揭载
        // allLifeSqureKindsEventInfo.eventList = allLifeSqureKindsEventInfo.eventList.concat(
        //   initEventList.eventList,
        // );
        // allLifeSqureKindsEventInfo.totalCount =
        //   allLifeSqureKindsEventInfo.totalCount + initEventList.totalCount;
      }

      if (
        // Number.parseInt(kindInfoMap[EventKind.KIND_NEIGHBORHOOD_INFORM]?.kindEventNum ?? '0') > 0
        true
      ) {
        // addKindTab(EventKind.KIND_NEIGHBORHOOD_INFORM, kindInfoMap[EventKind.KIND_NEIGHBORHOOD_INFORM].kindName);
        addKindTab(
          EventKind.KIND_NEIGHBORHOOD_INFORM,
          eventKindTypeInfo.valueToDescription[EventKind.KIND_NEIGHBORHOOD_INFORM].text,
        );
      }

      if (
        // Number.parseInt(kindInfoMap[EventKind.KIND_NEIGHBORHOOD_EVENT]?.kindEventNum ?? '0') > 0
        true
      ) {
        // addKindTab(EventKind.KIND_NEIGHBORHOOD_EVENT, kindInfoMap[EventKind.KIND_NEIGHBORHOOD_EVENT].kindName);
        addKindTab(
          EventKind.KIND_NEIGHBORHOOD_EVENT,
          eventKindTypeInfo.valueToDescription[EventKind.KIND_NEIGHBORHOOD_EVENT].text,
        );
      }
      if (
        // Number.parseInt(kindInfoMap[EventKind.KIND_NEIGHBORHOOD_SERVICE]?.kindEventNum ?? '0') > 0
        true
      ) {
        // addKindTab(EventKind.KIND_NEIGHBORHOOD_SERVICE, kindInfoMap[EventKind.KIND_NEIGHBORHOOD_SERVICE].kindName);
        addKindTab(
          EventKind.KIND_NEIGHBORHOOD_SERVICE,
          eventKindTypeInfo.valueToDescription[EventKind.KIND_NEIGHBORHOOD_SERVICE].text,
        );
      }
      if (
        // Number.parseInt(kindInfoMap[EventKind.KIND_NEIGHBORHOOD_RECRUITMENT]?.kindEventNum ?? '0') > 0
        true
      ) {
        // addKindTab(EventKind.KIND_NEIGHBORHOOD_RECRUITMENT, kindInfoMap[EventKind.KIND_NEIGHBORHOOD_RECRUITMENT].kindName);
        addKindTab(
          EventKind.KIND_NEIGHBORHOOD_RECRUITMENT,
          eventKindTypeInfo.valueToDescription[EventKind.KIND_NEIGHBORHOOD_RECRUITMENT].text,
        );
      }

      if (
        // allLifeSqureKindsEventInfo.eventList.length > 0
        allNeighborhoodKindsEventInfoNumber > 0
      ) {
        // allLifeSqureKindsEventInfo.eventList.sort((a, b) => {
        //   const eventStartCompare = DateUtils.dateStringCompare(a.eventStart, b.eventStart);
        //   if (eventStartCompare === 0) {
        //     return -DateUtils.dateStringCompare(a.insDate, b.insDate);
        //   } else {
        //     return -eventStartCompare;
        //   }
        // });

        // 作为第一个tab插入
        tabPanes.unshift(
          <TabPane tab={'すべて'} key={0}>
            <EventTitleList
              // targetEventKinds={kindsExists}
              // initEventListInfo={allLifeSqureKindsEventInfo}
              targetEventKinds={[
                EventKind.KIND_NEIGHBORHOOD_INFORM,
                EventKind.KIND_NEIGHBORHOOD_EVENT,
                EventKind.KIND_NEIGHBORHOOD_SERVICE,
                EventKind.KIND_NEIGHBORHOOD_RECRUITMENT,
              ]}
              // initEventListInfo={undefined}
            />
          </TabPane>,
        );
      }

      // 当存在ライフスクエア类型揭载的时候表示
      blockNeighborhoods = (
        <div>
          <Title
            level={4}
            style={{
              marginTop: 0,
              marginBottom: isMobile ? 10 : 0,
              marginLeft: 0,
              marginRight: 0,
              textAlign: isMobile ? 'center' : undefined,
            }}
          >
            町会・役所
          </Title>
          <Tabs tabPosition={'top'}>{tabPanes}</Tabs>
        </div>
      );
    }

    // ライフスクエア 板块
    let blockLifeSquare = <div />;
    if (
      // props.initEventInfo.eventsKindChildCare.eventList.length > 0 ||
      // props.initEventInfo.eventsKindStudent.eventList.length > 0 ||
      // props.initEventInfo.eventsKindOfficeFair.eventList.length > 0 ||
      // props.initEventInfo.eventsKindElderly.eventList.length > 0
      Number.parseInt(kindInfoMap[EventKind.KIND_ACTIVITIES]?.kindEventNum ?? '0') > 0 ||
      Number.parseInt(kindInfoMap[EventKind.KIND_CHILDCARE]?.kindEventNum ?? '0') > 0 ||
      Number.parseInt(kindInfoMap[EventKind.KIND_COMPROMISE]?.kindEventNum ?? '0') > 0 ||
      Number.parseInt(kindInfoMap[EventKind.KIND_MUTUAL_HELP]?.kindEventNum ?? '0') > 0 ||
      Number.parseInt(kindInfoMap[EventKind.KIND_LIFE_HINT]?.kindEventNum ?? '0') > 0 ||
      Number.parseInt(kindInfoMap[EventKind.KIND_OTHER_OF_LIFE_SQUARE]?.kindEventNum ?? '0') > 0
    ) {
      const kindsExists: EventKind[] = [];
      // const allLifeSqureKindsEventInfo: EventListInfo = {
      //   eventList: [],
      //   totalCount: 0,
      // };
      const allLifeSqureKindsEventInfoNumber =
        Number.parseInt(kindInfoMap[EventKind.KIND_ACTIVITIES]?.kindEventNum ?? '0') +
        Number.parseInt(kindInfoMap[EventKind.KIND_CHILDCARE]?.kindEventNum ?? '0') +
        Number.parseInt(kindInfoMap[EventKind.KIND_COMPROMISE]?.kindEventNum ?? '0') +
        Number.parseInt(kindInfoMap[EventKind.KIND_MUTUAL_HELP]?.kindEventNum ?? '0') +
        Number.parseInt(kindInfoMap[EventKind.KIND_LIFE_HINT]?.kindEventNum ?? '0') +
        Number.parseInt(kindInfoMap[EventKind.KIND_OTHER_OF_LIFE_SQUARE]?.kindEventNum ?? '0');

      const tabPanes = [];

      function addKindTab(targetKindId: EventKind, targetKindName: string) {
        kindsExists.push(targetKindId);
        // const eventFirst = new EventModel(initEventList.eventList[0]);
        tabPanes.push(
          <TabPane tab={targetKindName} key={targetKindId}>
            <EventTitleList
              targetEventKinds={[targetKindId]}
              // initEventListInfo={initEventList}
            />
          </TabPane>,
        );

        // すべて表示时的揭载
        // allLifeSqureKindsEventInfo.eventList = allLifeSqureKindsEventInfo.eventList.concat(
        //   initEventList.eventList,
        // );
        // allLifeSqureKindsEventInfo.totalCount =
        //   allLifeSqureKindsEventInfo.totalCount + initEventList.totalCount;
      }

      if (
        // Number.parseInt(kindInfoMap[EventKind.KIND_ACTIVITIES]?.kindEventNum ?? '0') > 0
        true
      ) {
        // addKindTab(EventKind.KIND_ACTIVITIES, kindInfoMap[EventKind.KIND_ACTIVITIES].kindName);
        addKindTab(
          EventKind.KIND_ACTIVITIES,
          eventKindTypeInfo.valueToDescription[EventKind.KIND_ACTIVITIES].text,
        );
      }

      if (
        // Number.parseInt(kindInfoMap[EventKind.KIND_CHILDCARE]?.kindEventNum ?? '0') > 0
        true
      ) {
        // addKindTab(EventKind.KIND_CHILDCARE, kindInfoMap[EventKind.KIND_CHILDCARE].kindName);
        addKindTab(
          EventKind.KIND_CHILDCARE,
          eventKindTypeInfo.valueToDescription[EventKind.KIND_CHILDCARE].text,
        );
      }
      if (
        // Number.parseInt(kindInfoMap[EventKind.KIND_COMPROMISE]?.kindEventNum ?? '0') > 0
        true
      ) {
        // addKindTab(EventKind.KIND_COMPROMISE, kindInfoMap[EventKind.KIND_COMPROMISE].kindName);
        addKindTab(
          EventKind.KIND_COMPROMISE,
          eventKindTypeInfo.valueToDescription[EventKind.KIND_COMPROMISE].text,
        );
      }
      if (
        // Number.parseInt(kindInfoMap[EventKind.KIND_MUTUAL_HELP]?.kindEventNum ?? '0') > 0
        true
      ) {
        // addKindTab(EventKind.KIND_MUTUAL_HELP, kindInfoMap[EventKind.KIND_MUTUAL_HELP].kindName);
        addKindTab(
          EventKind.KIND_MUTUAL_HELP,
          eventKindTypeInfo.valueToDescription[EventKind.KIND_MUTUAL_HELP].text,
        );
      }
      if (
        // Number.parseInt(kindInfoMap[EventKind.KIND_LIFE_HINT]?.kindEventNum ?? '0') > 0
        true
      ) {
        // addKindTab(EventKind.KIND_LIFE_HINT, kindInfoMap[EventKind.KIND_LIFE_HINT].kindName);
        addKindTab(
          EventKind.KIND_LIFE_HINT,
          eventKindTypeInfo.valueToDescription[EventKind.KIND_LIFE_HINT].text,
        );
      }

      if (true) {
        addKindTab(
          EventKind.KIND_OTHER_OF_LIFE_SQUARE,
          eventKindTypeInfo.valueToDescription[EventKind.KIND_OTHER_OF_LIFE_SQUARE].text,
        );
      }

      if (
        // allLifeSqureKindsEventInfo.eventList.length > 0
        allLifeSqureKindsEventInfoNumber > 0
      ) {
        // allLifeSqureKindsEventInfo.eventList.sort((a, b) => {
        //   const eventStartCompare = DateUtils.dateStringCompare(a.eventStart, b.eventStart);
        //   if (eventStartCompare === 0) {
        //     return -DateUtils.dateStringCompare(a.insDate, b.insDate);
        //   } else {
        //     return -eventStartCompare;
        //   }
        // });

        // 作为第一个tab插入
        tabPanes.unshift(
          <TabPane tab={'すべて'} key={0}>
            <EventTitleList
              // targetEventKinds={kindsExists}
              // initEventListInfo={allLifeSqureKindsEventInfo}
              targetEventKinds={[
                EventKind.KIND_ACTIVITIES,
                EventKind.KIND_CHILDCARE,
                EventKind.KIND_COMPROMISE,
                EventKind.KIND_MUTUAL_HELP,
                EventKind.KIND_LIFE_HINT,
                EventKind.KIND_OTHER_OF_LIFE_SQUARE,
              ]}
              // initEventListInfo={undefined}
            />
          </TabPane>,
        );
      }

      // 当存在ライフスクエア类型揭载的时候表示
      blockLifeSquare = (
        <div>
          <Title
            level={4}
            style={{
              marginTop: 0,
              marginBottom: isMobile ? 10 : 0,
              marginLeft: 0,
              marginRight: 0,
              textAlign: isMobile ? 'center' : undefined,
            }}
          >
            ライフスクエア
          </Title>
          <Tabs tabPosition={'top'}>{tabPanes}</Tabs>
        </div>
      );
    }

    // フードシェア 板块
    let blockFoodShare = <div />;
    if (
      // props.initEventInfo.eventsKindFoodShare.eventList.length > 0
      Number.parseInt(kindInfoMap[EventKind.KIND_FOOD_SHARE]?.kindEventNum ?? '0') > 0
    ) {
      // 当存在フードシェア的时候表示
      blockFoodShare = (
        <div>
          <Title
            level={4}
            style={{
              marginTop: 0,
              marginBottom: isMobile ? 10 : 0,
              marginLeft: 0,
              marginRight: 0,
              textAlign: isMobile ? 'center' : undefined,
            }}
          >
            フードシェア
          </Title>
          <EventTitleList
            targetEventKinds={[EventKind.KIND_FOOD_SHARE]}
            // initEventListInfo={props.initEventInfo.eventsKindFoodShare}
          />
        </div>
      );
    }

    // 代行 板块
    let blockActOnOnesBehalf = <div />;
    if (
      // props.initEventInfo.eventsKindActOnOnesBehalf.eventList.length > 0
      Number.parseInt(kindInfoMap[EventKind.KIND_ACT_ON_ONES_BEHALF]?.kindEventNum ?? '0') > 0
    ) {
      // 当存在フードシェア的时候表示
      blockActOnOnesBehalf = (
        <div>
          <Title
            level={4}
            style={{
              marginTop: 0,
              marginBottom: isMobile ? 10 : 0,
              marginLeft: 0,
              marginRight: 0,
              textAlign: isMobile ? 'center' : undefined,
            }}
          >
            代行
          </Title>
          <EventTitleList
            targetEventKinds={[EventKind.KIND_ACT_ON_ONES_BEHALF]}
            // initEventListInfo={props.initEventInfo.eventsKindActOnOnesBehalf}
          />
        </div>
      );
    }

    // 旧种类揭载(法人揭载) 板块
    let blockOldEvent = <div />;
    if (
      // props.initEventInfo.eventsKindInform.eventList.length > 0 ||
      // props.initEventInfo.eventsKindFree.eventList.length > 0 ||
      // props.initEventInfo.eventsKindCampaign.eventList.length > 0 ||
      // props.initEventInfo.eventsKindRecruitment.eventList.length > 0 ||
      // props.initEventInfo.eventsKindPaid.eventList.length > 0 ||
      // props.initEventInfo.eventsKindOther.eventList.length > 0
      Number.parseInt(kindInfoMap[EventKind.KIND_INFORM]?.kindEventNum ?? '0') > 0 ||
      Number.parseInt(kindInfoMap[EventKind.KIND_FREE]?.kindEventNum ?? '0') > 0 ||
      Number.parseInt(kindInfoMap[EventKind.KIND_CAMPAIGN]?.kindEventNum ?? '0') > 0 ||
      Number.parseInt(kindInfoMap[EventKind.KIND_RECRUITMENT]?.kindEventNum ?? '0') > 0 ||
      Number.parseInt(kindInfoMap[EventKind.KIND_PAID]?.kindEventNum ?? '0') > 0 ||
      Number.parseInt(kindInfoMap[EventKind.KIND_OTHER]?.kindEventNum ?? '0') > 0
    ) {
      const kindsExists: EventKind[] = [];
      // const allOldKindsEventInfo: EventListInfo = {
      //   eventList: [],
      //   totalCount: 0,
      // };
      const allOldKindsEventInfoNum =
        Number.parseInt(kindInfoMap[EventKind.KIND_INFORM]?.kindEventNum ?? '0') +
        Number.parseInt(kindInfoMap[EventKind.KIND_FREE]?.kindEventNum ?? '0') +
        Number.parseInt(kindInfoMap[EventKind.KIND_CAMPAIGN]?.kindEventNum ?? '0') +
        Number.parseInt(kindInfoMap[EventKind.KIND_RECRUITMENT]?.kindEventNum ?? '0') +
        Number.parseInt(kindInfoMap[EventKind.KIND_PAID]?.kindEventNum ?? '0') +
        Number.parseInt(kindInfoMap[EventKind.KIND_OTHER]?.kindEventNum ?? '0');
      const tabPanes: ReactNode[] = [];

      // function addKindTab(targetKindId: EventKind, initEventList: EventListInfo) {
      //   kindsExists.push(targetKindId);
      //   const eventFirst = new EventModel(initEventList.eventList[0]);
      //   tabPanes.push(
      //     <TabPane tab={eventFirst.kindId.text()} key={eventFirst.kindId.value}>
      //       <EventTitleList
      //         targetEventKinds={[targetKindId]}
      //       // initEventListInfo={initEventList}
      //       />
      //     </TabPane>,
      //   );
      function addKindTab(targetKindId: EventKind, targetKindName: string) {
        kindsExists.push(targetKindId);
        // const eventFirst = new EventModel(initEventList.eventList[0]);
        tabPanes.push(
          <TabPane tab={targetKindName} key={targetKindId}>
            <EventTitleList
              targetEventKinds={[targetKindId]}
              // initEventListInfo={initEventList}
            />
          </TabPane>,
        );

        // すべて表示时的揭载
        // allOldKindsEventInfo.eventList = allOldKindsEventInfo.eventList.concat(
        //   initEventList.eventList,
        // );
        // allOldKindsEventInfo.totalCount = allOldKindsEventInfo.totalCount + initEventList.totalCount;
      }
      if (
        // Number.parseInt(kindInfoMap[EventKind.KIND_INFORM]?.kindEventNum ?? '0') > 0
        true
      ) {
        // addKindTab(EventKind.KIND_INFORM, kindInfoMap[EventKind.KIND_INFORM]?.kindName);
        addKindTab(
          EventKind.KIND_INFORM,
          eventKindTypeInfo.valueToDescription[EventKind.KIND_INFORM].text,
        );
      }
      if (
        // Number.parseInt(kindInfoMap[EventKind.KIND_FREE]?.kindEventNum ?? '0') > 0
        true
      ) {
        // addKindTab(EventKind.KIND_FREE, kindInfoMap[EventKind.KIND_FREE]?.kindName);
        addKindTab(
          EventKind.KIND_FREE,
          eventKindTypeInfo.valueToDescription[EventKind.KIND_FREE].text,
        );
      }
      if (
        // Number.parseInt(kindInfoMap[EventKind.KIND_CAMPAIGN]?.kindEventNum ?? '0') > 0
        true
      ) {
        // addKindTab(EventKind.KIND_CAMPAIGN, kindInfoMap[EventKind.KIND_CAMPAIGN]?.kindName);
        addKindTab(
          EventKind.KIND_CAMPAIGN,
          eventKindTypeInfo.valueToDescription[EventKind.KIND_CAMPAIGN].text,
        );
      }
      if (
        // Number.parseInt(kindInfoMap[EventKind.KIND_RECRUITMENT]?.kindEventNum ?? '0') > 0
        true
      ) {
        // addKindTab(EventKind.KIND_RECRUITMENT, kindInfoMap[EventKind.KIND_RECRUITMENT]?.kindName);
        addKindTab(
          EventKind.KIND_RECRUITMENT,
          eventKindTypeInfo.valueToDescription[EventKind.KIND_RECRUITMENT].text,
        );
      }
      if (
        // Number.parseInt(kindInfoMap[EventKind.KIND_PAID]?.kindEventNum ?? '0') > 0
        true
      ) {
        // addKindTab(EventKind.KIND_PAID, kindInfoMap[EventKind.KIND_PAID]?.kindName);
        addKindTab(
          EventKind.KIND_PAID,
          eventKindTypeInfo.valueToDescription[EventKind.KIND_PAID].text,
        );
      }
      if (
        // Number.parseInt(kindInfoMap[EventKind.KIND_OTHER]?.kindEventNum ?? '0') > 0
        true
      ) {
        addKindTab(
          EventKind.KIND_OTHER,
          eventKindTypeInfo.valueToDescription[EventKind.KIND_OTHER].text,
        );
      }
      if (allOldKindsEventInfoNum > 0) {
        tabPanes.unshift(
          <TabPane tab={'すべて'} key={'0'}>
            <EventTitleList targetEventKinds={kindsExists} />
          </TabPane>,
        );
      }

      blockOldEvent = (
        <div>
          <Title
            level={4}
            style={{
              marginTop: 0,
              marginBottom: isMobile ? 10 : 0,
              marginLeft: 0,
              marginRight: 0,
              textAlign: isMobile ? 'center' : undefined,
            }}
          >
            ポスター
          </Title>
          <Tabs tabPosition={'top'}>{tabPanes}</Tabs>
        </div>
      );
    }

    blockEvent = (
      <Card
        bodyStyle={{
          paddingRight: 10,
          paddingLeft: 10,
          paddingTop: 10,
        }}
      >
        <div
          style={{
            width: '100%',
            minHeight: 25,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginBottom: props.mobile ? 10 : 0,
          }}
        >
          {/* <Text>※下記投稿内容確認するためには住所確認が必要です。</Text> */}
          {!props.mobile ? (
            <div />
          ) : (
            <Button
              shape="round"
              icon={<SolutionOutlined />}
              href="/issue/"
              onClick={(e) => {
                e.preventDefault();
                // history.push('/issue/');
                if (
                  globalThis.location.pathname == '/issue/' ||
                  globalThis.location.pathname == '/issue'
                ) {
                  globalThis.location.href = '/issue';
                } else {
                  history.push('/issue/');
                }
              }}
            >
              投稿
            </Button>
          )}
          <Text style={{ float: 'right' }}>※{displayAreaName}</Text>
        </div>
        {blockManagementAssociation}
        {blockNeighborhoods}
        {blockLifeSquare}
        {blockFoodShare}
        {blockActOnOnesBehalf}
        {blockOldEvent}
      </Card>
    );
  }

  return <div>{blockEvent}</div>;
};

export default QuudyHomeEventBlock;
